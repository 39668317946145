export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST"
export const CREATE_QUESTION_REQUEST_SUCCESS = "CREATE_QUESTION_REQUEST_SUCCESS"

export const GET_LOGISTIC_DETAIL_REQUEST = "GET_LOGISTIC_DETAIL_REQUEST"
export const GET_LOGISTIC_DETAIL_REQUEST_SUCCESS = "GET_LOGISTIC_DETAIL_REQUEST_SUCCESS"

export const GET_LOGISTICS_REQUEST = "GET_LOGISTICS_REQUEST"
export const GET_LOGISTICS_REQUEST_SUCCESS = "GET_LOGISTICS_REQUEST_SUCCESS"

export const GET_LOGISTICS_AWB_REQUEST = "GET_LOGISTICS_AWB_REQUEST"
export const GET_LOGISTICS_AWB_REQUEST_SUCCESS = "GET_LOGISTICS_AWB_REQUEST_SUCCESS"

export const UPDATE_LOGISTICS_REQUEST = "UPDATE_LOGISTICS_REQUEST"
export const UPDATE_LOGISTICS_REQUEST_SUCCESS = "UPDATE_LOGISTICS_REQUEST_SUCCESS"

export const DELETE_LOGISTICS_REQUEST = "DELETE_LOGISTICS_REQUEST"
export const DELETE_LOGISTICS_REQUEST_SUCCESS = "DELETE_LOGISTICS_REQUEST_SUCCESS"

export const GET_SEARCH_LOGISTICS_REQUEST = "GET_SEARCH_LOGISTICS_REQUEST"
export const GET_SEARCH_LOGISTICS_REQUEST_SUCCESS = "GET_SEARCH_LOGISTICS_REQUEST_SUCCESS"
