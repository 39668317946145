import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux"
import * as XLSX from 'xlsx';
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import {useFormik} from "formik"
import * as Yup from "yup"
import {getAWBDetails, updateAWB} from "../../store/AWB/actions";
import {useParams} from "react-router-dom";


import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import AWBColumns from "./AWBColumns";
import Nulled from "../../components/Null/Nulled";
import {getLogisticsAWB} from "../../store/Logistic/actions";


const Detail = props => {
    const dispatch = useDispatch()
    const {id} = useParams();

    const detail = useSelector((state) => state.AWB.detail);

    const isLoading = useSelector((state) => state.Logistic.isLoadingAwb);
    const error = useSelector((state) => state.Logistic.errorAwb);
    const array = useSelector((state) => state.Logistic.arrayAwb);

    const [newArray, setNewArray] = useState([])
    const [awbId, setAwbId] = useState('awb')

    useEffect(() => {
        let item = {
            awbId: id
        }
        dispatch(getLogisticsAWB(item))
    }, [])

    useEffect(() => {
        if (array.length > 0) {
            const newArray = array.map(({
                                            _id,
                                            primary: {primaryTracking},
                                            primary: {primaryGrossWeight},
                                            primary: {primaryVolumeWeight},
                                            primary: {primaryNumberOfSeat},
                                            last: {lastGrossWeight},
                                            last: {lastVolumeWeight},
                                            last: {lastNumberOfSeat},
                                            productPrice,
                                            company: {companyName},
                                            company: {phone},
                                            company: {companyId},
                                            additionalDescription,
                                        }) =>
                ({
                    "ID": _id,
                    "პირველადი თრექინგი": primaryTracking,
                    "პირველადი ბრუტო წონა": primaryGrossWeight,
                    "პირველადი მოცულობითი წონა": primaryVolumeWeight,
                    "პირველადი ადგილების რაოდენობა": primaryNumberOfSeat,

                    "საბოლოო ბრუტო წონა": lastGrossWeight,
                    "საბოლოო მოცულობითი წონა": lastVolumeWeight,
                    "საბოლოო ადგილების რაოდენობა": lastNumberOfSeat,
                    "ფასი": productPrice,

                    "კომპანიის სახელი": companyName,
                    "კომპანიის ტელეფონი": phone,
                    "კომპანიის საიდენტიფიკაციო": companyId,
                    additionalDescription
                }))

            setNewArray(newArray)
        }

    }, [array])


    useEffect(() => {
        let item = {
            id
        }
        dispatch(getAWBDetails(item))
    }, [])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: detail.title || "",
            commentary: detail.commentary || "",
            status: detail.status || "active",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("შეიყვანეთ Title"),
        }),
        onSubmit: (values) => {
            //validation.values.nameEn
            let item = {
                id,
                title: values.title,
                commentary: values.commentary,
                status: values.status,
            }
            dispatch(updateAWB(item))
        }
    })
    const exportToExcel = () => {

        const worksheet = XLSX.utils.json_to_sheet(newArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Employees");
        XLSX.writeFile(workbook, "employees.xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="AWB-ს განახლება"/>
                    {
                        detail._id &&

                        <>
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <Form
                                                className="form-horizontal"
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    return false
                                                }}
                                            >

                                                <Row>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label">Title</Label>
                                                            <Input
                                                                name="title"
                                                                className="form-control"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.title || ""}
                                                                invalid={
                                                                    validation.touched.title && validation.errors.title ? true : false
                                                                }
                                                            />
                                                            {validation.touched.email && validation.errors.title ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.title}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label
                                                                className="form-label">სტატუსი</Label>
                                                            <select
                                                                name="status"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.status || ""}
                                                                className="form-control m-input">
                                                                <option value="">აირჩიეთ ერთ-ერთი
                                                                </option>
                                                                <option value="active">აქტიური</option>
                                                                <option value="came">ჩამოვიდა</option>
                                                                <option value="archive">არქივი</option>
                                                            </select>
                                                        </div>
                                                    </Col>
                                                    <Col lg="6">
                                                        <div className="mb-3">
                                                            <Label className="form-label">კომენტარი</Label>
                                                            <Input
                                                                name="commentary"
                                                                className="form-control"
                                                                type="textarea"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.commentary || ""}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="12">

                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <Col lg="6">
                                                        <button
                                                            className="btn btn-primary w-100 waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            განახლება
                                                        </button>
                                                    </Col>
                                                </Row>

                                            </Form>

                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>

                            <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                         breadcrumbItem="AWB-ს სია"/>

                            {isLoading ? (
                                <Preloader/>
                            ) : error ? (
                                <Error/>
                            ) : (
                                <Row>

                                    <Col xl={12}>
                                        <Card>
                                            <CardBody>
                                                <button onClick={exportToExcel}>Export to Excel</button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    {
                                        array.length > 0
                                            ?
                                            <Col lg={12} style={{overflowX:'auto'}}>
                                                <AWBColumns
                                                    array={array}
                                                />
                                            </Col>
                                            : <Nulled/>
                                    }
                                </Row>
                            )}
                        </>
                    }
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Detail)
