import React from "react";
import {Col, Container, Row} from "reactstrap";
import errorImg from "../../assets/images/500-error.png";

const ErrorPage = (props) =>{
    return (
        <React.Fragment>
            <div className="my-5 pt-sm-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="text-center">
                                <div>
                                    <Row className="justify-content-center">
                                        <Col sm={4}>
                                            <div className="error-img">
                                                <img src={errorImg} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <h4 className="text-uppercase mt-4" style={{color:'red'}}>Internal Server Error</h4>
                                <h5 className="mt-4" style={{color:'red'}}>{props.error}</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ErrorPage
