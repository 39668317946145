import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Col} from "reactstrap"
import withRouter from "../../components/Common/withRouter";
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {
    deleteAdmin, deleteCompany, deleteLogistic,
} from "../../store/actions";
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";
import {countryToGeo, logisticStatus, receiverCondition, typeOfShipment, typeOfTransport} from "../../utils/Status";
import moment from "moment";
import ModalDetail from "../../components/Modal/ModalDetail";


const ConfirmedColumns = props => {
    const dispatch = useDispatch();
    const candidateRole = useSelector((state) => state.Authorisation.candidate.role);
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteLogistic(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }


    const [modal_xlarge, setmodal_xlarge] = useState(false)

    const tog_large = (id, key) => {
        setmodal_xlarge(!modal_xlarge)
        setId(id)
    }

    const onCloseModal = ( ) => {
        setmodal_xlarge(!modal_xlarge)
    }

    const Columns = [
        {
            dataField: "_id",
            text: "",
            formatter: (cellContent, row, key) => (
                <>
                 <span
                     style={{cursor: 'pointer', float: 'left'}}
                     onClick={() => {
                         tog_large(row._id, key)
                     }}
                     className="px-3 text-primary">
                         <FeatherIcon icon="eye" className="icon-xs icon me-2 text-pending"/>
                     </span>
                </>
            ),
        },
        {
            dataField: "status",
            text: "სტატუსი",
            formatter: (cellContent, row, key) => (
                <>
                    {logisticStatus(row.status)}
                </>
            ),
        },

        {
            dataField: "transporterName",
            text: "გადამზიდი",
            sort: true,
        },
        {
            dataField: "sellerName",
            text: "გაყიდვები",
            sort: true,
        },
        {
            dataField: "company.companyName",
            text: "კომპანიის სახელი",
            sort: true,
        },
        {
            dataField: "_id",
            text: "ტვირთის Id",
            sort: true,
        },

        {
            dataField: "senderCountry",
            text: "გამომგზავნი ქვეყანა",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {countryToGeo(row.senderCountry)}
                </>
            ),
        },
        {
            dataField: "receiverCountry",
            text: "მიმღები ქვეყანა",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {countryToGeo(row.receiverCountry)}
                </>
            ),
        },
        {
            dataField: "typeOfTransport",
            text: "ტრანსპორტირება",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {typeOfTransport(row.typeOfTransport)}
                </>
            ),
        },
        {
            dataField: "typeOfShipment",
            text: "გადაზიდვის ტიპი",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {typeOfShipment(row.typeOfShipment)}
                </>
            ),
        },

        {
            dataField: "senderCondition",
            text: "გამოგზავნის პირობა",
            sort: true,
        },
        {
            dataField: "receiverCondition",
            text: "მიღების პირობა",
            sort: true,
            formatter: (cellContent, row, key) => (
                <>
                    {receiverCondition(row.receiverCondition)}
                </>
            ),
        },
        {
            dataField: "productName",
            text: "პროდუქციის დასახელება",
            sort: true,
        },
        {
            dataField: "takeAddress",
            text: "აღების მისამართი",
            sort: true,
        },
        {
            dataField: "sender",
            text: "გამომგზავნი",
            sort: true,
        },
        {
            dataField: "primary.primaryGrossWeight",
            text: "პრ. ბრუტო წონა",
            sort: true,
        },

        {
            dataField: "primary.primaryCubicWeight",
            text: "პრ. კუბატურა",
            sort: true,
        },

        {
            dataField: "primary.primarySize",
            text: "პრ. ზომები",
            sort: true,
        },

        {
            dataField: "primary.primaryVolumeWeight",
            text: "პრ.მოცულობითი წონა",
            sort: true,
        },

        {
            dataField: "primary.primaryNumberOfSeat",
            text: "პრ. ადგილების რაოდენობა",
            sort: true,
        },
        {
            dataField: "primary.primaryRatePerKilogram",
            text: "პრ. ტარიფი კილოგრამზე",
            sort: true,
        },

        {
            dataField: "primary.primaryTotalTariff",
            text: "პრ. ჯამური ტარიფი",
            sort: true,
        },

        {
            dataField: "primary.primaryProfit",
            text: "პრ. მოგება",
            sort: true,
        },

        {
            dataField: "primary.primaryTracking",
            text: "პრ. თრექინგი",
            sort: true,
        },

        {
            dataField: "productPrice",
            text: "ფასი",
            sort: true,
        },

        {
            dataField: "insurance",
            text: "დაზღვევა ",
            sort: true,
        },

        {
            dataField: "additionalDescription",
            text: "დამატებითი ინფორმაცია",
            sort: true,
        },


        {
            dataField: "createdAt",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <Link to={`/detail-logistic/${row._id}`}
                          className="px-3 text-primary"><FeatherIcon icon="edit"
                                                                     className="icon-xs icon me-2 text-pending"/>
                    </Link>
                   <span>
    {
        candidateRole === 'admin' &&      <FeatherIcon
            style={{cursor: 'pointer'}}
            onClick={() => {
                handleOpenAlertModal(row._id, key)
            }}
            icon="trash" className="icon-xs icon me-2 text-danger"/>
    }

                     </span>
                </>
            ),
        },

    ]

    return (
        <>
            <BootstrapTable
                keyField="_id"
                data={props.array}
                columns={Columns}
            />

            <ModalDetail
                modal_xlarge={modal_xlarge}
                onCloseModal={onCloseModal}
                id={id}
            />

            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        style={{backgroundColor: '#404451'}}
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default withRouter(ConfirmedColumns);
