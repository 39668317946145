import {
    CREATE_COMPANY_REQUEST,
    DELETE_COMPANY_REQUEST,
    GET_COMPANY_DETAIL_REQUEST,
    GET_COMPANIES_REQUEST,
    UPDATE_COMPANY_REQUEST,
    SEARCH_COMPANIES_REQUEST
} from "./actionTypes"


export const createCompany = (data) => ({
    type: CREATE_COMPANY_REQUEST,
    payload :data
})

export const getCompanies = (data) => ({
    type: GET_COMPANIES_REQUEST,
    payload :data
})

export const searchCompanies = (data) => ({
    type: SEARCH_COMPANIES_REQUEST,
    payload :data
})

export const getCompanyDetails = (data) => ({
    type: GET_COMPANY_DETAIL_REQUEST,
    payload :data
})

export const updateCompany = (data) => ({
    type: UPDATE_COMPANY_REQUEST,
    payload :data
})

export const deleteCompany = (data) => ({
    type: DELETE_COMPANY_REQUEST,
    payload :data
})
