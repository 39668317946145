import {
  DELETE_FILE_REQUEST_SUCCESS,
  GET_FILES_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  Error: null,
  array : []
}

const Photos = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FILES_REQUEST_SUCCESS:
      return state = {
        ...state,
        array: action.callBack.result
      }
    case DELETE_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        array: state.array.filter(item => item._id !== action.payload.id),
      }
    default:
      return state
  }
}

export default Photos
