export const COMPANY_FAIL = "COMPANY_FAIL"

export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST"
export const CREATE_COMPANY_REQUEST_SUCCESS = "CREATE_COMPANY_REQUEST_SUCCESS"

export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST"
export const GET_COMPANIES_REQUEST_SUCCESS = "GET_COMPANIES_REQUEST_SUCCESS"

export const GET_COMPANY_DETAIL_REQUEST = "GET_COMPANY_DETAIL_REQUEST"
export const GET_COMPANY_DETAIL_REQUEST_SUCCESS = "GET_COMPANY_DETAIL_REQUEST_SUCCESS"

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST"
export const UPDATE_COMPANY_REQUEST_SUCCESS = "UPDATE_COMPANY_REQUEST_SUCCESS"

export const DELETE_COMPANY_REQUEST = "DELETE_COMPANY_REQUEST"
export const DELETE_COMPANY_REQUEST_SUCCESS = "DELETE_COMPANY_REQUEST_SUCCESS"

export const SEARCH_COMPANIES_REQUEST = "SEARCH_COMPANIES_REQUEST"
export const SEARCH_COMPANIES_REQUEST_SUCCESS = "SEARCH_COMPANIES_REQUEST_SUCCESS"
