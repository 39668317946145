import {
    GET_AWBES_REQUEST_SUCCESS,
    DELETE_AWB_REQUEST_SUCCESS, GET_AWB_DETAIL_REQUEST_SUCCESS, GET_AWBES_REQUEST
} from "./actionTypes"

const INIT_STATE = {
    isLoading: true,
    error: null,
    array: [],
    arrayCount: 0,
    detail: {
        _id : '',
        title : '',
        status : '',
    },
}

const AWB = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AWBES_REQUEST:
            return state = {
                ...state,
                array: [],
                arrayCount: 0,
                isLoading: false,
            }
        case GET_AWBES_REQUEST_SUCCESS:
            return state = {
                ...state,
                array: action.callBack.result,
                arrayCount: action.callBack.resultCount,
                isLoading: false,
            }
        case DELETE_AWB_REQUEST_SUCCESS:
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }
        case GET_AWB_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                detail: action.callBack.result,
            }
        default:
            return state
    }
}

export default AWB
