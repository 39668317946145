import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Container, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createAdmin, getAdmins, getAWBes, getCompanies, getLogistics, getUsers} from "../../store/actions";

import WaitingColumns from "./WaitingColumns";
import TariffIssuedColumns from "./TariffIssuedColumns";
import ConfirmedColumns from "./ConfirmedColumns";
import StockColumns from "./StockColumns";
import RejectColumns from "./RejectColumns";
import AWBColumns from "./AWBColumns";
import DomesticShippingColumns from "./DomesticShippingColumns";
import OrganizeLoadColumns from "./OrganizeLoadColumns";
import InstructionColumns from "./InstructionColumns";
import ArchiveColumns from "./ArchiveColumns";
import DeclarationColumns from "./DeclarationColumns";
import CourierColumns from "./CourierColumns";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Nulled from "../../components/Null/Nulled";
import {useParams} from "react-router-dom";


const Waiting = props => {
    const dispatch = useDispatch();
    const {status} = useParams();
    const isLoading = useSelector((state) => state.Logistic.isLoading);
    const error = useSelector((state) => state.Logistic.error);
    const array = useSelector((state) => state.Logistic.array);


    const [breadcrumbs, setBreadcrumbs] = useState('')
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(500)
    useEffect(() => {
        let item = {
            status: status,
            skip: 0,
            limit: limit
        }
        dispatch(getLogistics(item))
    }, [status])

    useEffect(() => {
        if (status === 'waiting') {
            setBreadcrumbs('მოლოდინის რეჟიმი')
        } else if (status === 'tariffIssued') {
            setBreadcrumbs('ტარიფი გაცემულია')
        } else if (status === 'confirmed') {
            setBreadcrumbs('დადასტურდა')
        } else if (status === 'reject') {
            setBreadcrumbs('უარი')
        } else if (status === 'organizeLoad') {
            setBreadcrumbs('ტვირთის ორგანიზება')
        } else if (status === 'domesticShipping') {
            setBreadcrumbs('შიდა გადაზიდვა')
        } else if (status === 'stock') {
            setBreadcrumbs('საწყობშია')
        } else if (status === 'instruction') {
            setBreadcrumbs('ინსტრუქცია')
        } else if (status === 'AWB') {
            setBreadcrumbs('AWB')
        } else if (status === 'instruction') {
            setBreadcrumbs('ინსტრუქცია')
        } else if (status === 'declaration') {
            setBreadcrumbs('დეკლარაცია')
        } else if (status === 'courier') {
            setBreadcrumbs('საკურიერო')
        } else if (status === 'archive') {
            setBreadcrumbs('არქივი')
        }

    }, [status])

    useEffect(() => {
        dispatch(getAdmins())

        let item = {
            skip: 0,
            limit: 10000,
            status: 'active'
        }
        dispatch(getAWBes(item))

    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem={breadcrumbs}/>
                    {isLoading ? (
                        <Preloader/>
                    ) : error ? (
                        <Error/>
                    ) : (
                        <Row>
                            {
                                array.length > 0
                                    ?
                                    <Col lg={12} style={{overflowX: 'auto'}}>
                                        {
                                            (status === 'waiting' ) &&
                                            <WaitingColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            status === 'tariffIssued' &&
                                            <TariffIssuedColumns
                                                status={status}
                                                array={array}
                                            />
                                        }

                                        {
                                            (status === 'confirmed'  ) &&
                                            <ConfirmedColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            (  status === 'AWB'   || status === 'stock'  ) &&
                                            <StockColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            (  status === 'reject'  ) &&
                                            <RejectColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                      {/*  {
                                            (  status === 'AWB') &&
                                            <AWBColumns
                                                status={status}
                                                array={array}
                                            />
                                        }*/}
                                        {
                                            (  status === 'domesticShipping'  ) &&
                                            <DomesticShippingColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            ( status === 'organizeLoad') &&
                                            <OrganizeLoadColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            (status === 'instruction'  ) &&
                                            <InstructionColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            ( status === 'archive') &&
                                            <ArchiveColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            (  status === 'declaration') &&
                                            <DeclarationColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                        {
                                            (status === 'courier') &&
                                            <CourierColumns
                                                status={status}
                                                array={array}
                                            />
                                        }
                                    </Col>
                                    : <Nulled/>
                            }
                        </Row>
                    )}
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Waiting);
