import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Container, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createAdmin, getAdmins, getAWBes, getCompanies, getUsers} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import {notification} from "antd";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Nulled from "../../components/Null/Nulled";


const Index = props => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.AWB.isLoading);
    const error = useSelector((state) => state.AWB.error);
    const array = useSelector((state) => state.AWB.array);


    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(100)
    useEffect(() => {
        let item = {
            skip: 0,
            limit: limit,
            status:'all'
        }
        dispatch(getAWBes(item))
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="AWB"/>
                    {isLoading ? (
                        <Preloader/>
                    ) : error ? (
                        <Error/>
                    ) : (
                        <Row>
                            {
                                array.length > 0
                                    ?
                                    <Col lg={12} style={{overflowX:'auto'}}>
                                        <ObjectColumns
                                            array={array}
                                        />
                                    </Col>
                                    : <Nulled/>
                            }
                        </Row>
                    )}
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Index);
