import {
    GET_LOGISTIC_DETAIL_REQUEST,
    GET_LOGISTIC_DETAIL_REQUEST_SUCCESS, GET_LOGISTICS_REQUEST, GET_LOGISTICS_REQUEST_SUCCESS,
    GET_LOGISTICS_AWB_REQUEST_SUCCESS, DELETE_LOGISTICS_REQUEST_SUCCESS, GET_SEARCH_LOGISTICS_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array: [],
    arrayCount: 0,

    isLoading: true,
    error: null,
    detail: {
        _id: '',
        company : {},
        weight : {},
        status: ''
    },

    arrayAwb: [],
    arrayAwbCount: 0,
    isLoadingAwb: true,
    errorAwb: null,
}


const Admin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOGISTIC_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: null,
            }
        case GET_LOGISTIC_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                detail: action.callBack.result,
            }
        case GET_LOGISTICS_REQUEST:
            return {
                ...state,
                isLoading: true,
                array:[]
            }
        case GET_LOGISTICS_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                array: action.callBack.result,
            }
        case GET_SEARCH_LOGISTICS_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                array: action.callBack.result,
            }
        case GET_LOGISTICS_AWB_REQUEST_SUCCESS:
            return {
                ...state,
                isLoadingAwb: false,
                arrayAwb: action.callBack.result,
            }
        case DELETE_LOGISTICS_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                array: state.array.filter(todo => todo._id !== action.payload.id),
                arrayAwb: state.array.filter(todo => todo._id !== action.payload.id)
            }
        default:
            return state
    }
}

export default Admin
