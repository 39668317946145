import {
    GET_ADMIN_REQUEST_SUCCESS,
    DELETE_ADMIN_REQUEST_SUCCESS, GET_ADMIN_DETAIL_REQUEST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array: [],
    detail: {
        _id: '',
    },

}


const Admin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ADMIN_REQUEST_SUCCESS:
            return {
                ...state,
                array: action.callBack.array,
            }
        case DELETE_ADMIN_REQUEST_SUCCESS:
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }
        case GET_ADMIN_DETAIL_REQUEST_SUCCESS:
            return {
                ...state,
                detail: action.callBack.result,
            }
        default:
            return state
    }
}

export default Admin
