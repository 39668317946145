import {
  GET_FILES_REQUEST,
  DELETE_FILE_REQUEST,
  ADD_FILES_REQUEST
} from "./actionTypes"

export const getFiles = (data) => ({
  type: GET_FILES_REQUEST,
  payload :data
})


export const deleteFile = (data) => ({
  type: DELETE_FILE_REQUEST,
  payload :data
})

export const addFile = (data) => ({
  type: ADD_FILES_REQUEST,
  payload :data
})
