import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux"
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import {useFormik} from "formik"
import * as Yup from "yup"
import {createCompany} from "../../store/Company/actions";
import {createAWB} from "../../store/AWB/actions";

const Add = props => {
    const dispatch = useDispatch()

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            commentary: "",
            status: "active",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("შეიყვანეთ Title"),
        }),
        onSubmit: (values) => {
            //validation.values.nameEn
            let item = {
                title: values.title,
                commentary: values.commentary,
                status: values.status,
            }
            dispatch(createAWB(item))
            validation.resetForm();
        }
    })


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="ახალი AWB-ს დამატება"/>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >

                                        <Row>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">თითლი</Label>
                                                    <Input
                                                        name="title"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={
                                                            validation.touched.title && validation.errors.title ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.title ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.title}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label
                                                        className="form-label">სტატუსი</Label>
                                                    <select
                                                        name="status"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.status || ""}
                                                        className="form-control m-input">
                                                        <option value="">აირჩიეთ ერთ-ერთი
                                                        </option>
                                                        <option value="active">აქტიური</option>
                                                        <option value="came">ჩამოვიდა</option>
                                                        <option value="archive">არქივი</option>
                                                    </select>
                                                </div>
                                            </Col>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">კომენტარი</Label>
                                                    <Input
                                                        name="commentary"
                                                        className="form-control"
                                                        type="textarea"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.commentary || ""}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg="6">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    დამატება
                                                </button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Add)
