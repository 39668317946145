export const AWB_FAIL = "COMPANY_FAIL"

export const CREATE_AWB_REQUEST = "CREATE_AWB_REQUEST"
export const CREATE_AWB_REQUEST_SUCCESS = "CREATE_AWB_REQUEST_SUCCESS"

export const GET_AWBES_REQUEST = "GET_AWBES_REQUEST"
export const GET_AWBES_REQUEST_SUCCESS = "GET_AWBES_REQUEST_SUCCESS"

export const GET_AWB_DETAIL_REQUEST = "GET_AWB_DETAIL_REQUEST"
export const GET_AWB_DETAIL_REQUEST_SUCCESS = "GET_AWB_DETAIL_REQUEST_SUCCESS"

export const UPDATE_AWB_REQUEST = "UPDATE_AWB_REQUEST"
export const UPDATE_AWB_REQUEST_SUCCESS = "UPDATE_AWB_REQUEST_SUCCESS"

export const DELETE_AWB_REQUEST = "DELETE_AWB_REQUEST"
export const DELETE_AWB_REQUEST_SUCCESS = "DELETE_AWB_REQUEST_SUCCESS"
