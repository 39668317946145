import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux"
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Form, FormFeedback, Input, Label, Row} from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"

import {useFormik} from "formik"
import * as Yup from "yup"
import {createCompany, getCompanyDetails, updateCompany} from "../../store/Company/actions";
import {useParams} from "react-router-dom";

const Add = props => {
    const dispatch = useDispatch()
    const {id} = useParams();

    const isLoading = useSelector((state) => state.Company.isLoading);
    const error = useSelector((state) => state.Company.error);
    const detail = useSelector((state) => state.Company.detail);

    useEffect(()=>{
        let item = {
            id
        }
        dispatch(getCompanyDetails(item))
    },[])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: detail._id || '',
            email: detail.email || "",
            companyName: detail.companyName || "",
            companyId: detail.companyId || "",
            phone: detail.phone || "",
            address: detail.address || "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("შეიყვანეთ ელ-ფოსტა"),
            companyName: Yup.string().required("შეიყვანეთ სახელი"),
            companyId: Yup.string().required("შეიყვანეთ კომპანის საიდენტიფიკაციო ნომერი"),
            phone: Yup.string().required("შეიყვანეთ ტელეფონი"),
            address: Yup.string().required("შეიყვანეთ მისამართი"),
        }),
        onSubmit: (values) => {
            //validation.values.nameEn
            let item = {
                id: id,
                email: values.email,
                companyName: values.companyName,
                companyId: values.companyId,
                phone: values.phone,
                address: values.address,
            }
           dispatch(updateCompany(item))
           // validation.resetForm();
        }
    })


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="ახალი კომპანიის დამატება"/>

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >

                                        <Row>

                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">კომპანიის სახელი</Label>
                                                    <Input
                                                        name="companyName"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.companyName || ""}
                                                        invalid={
                                                            validation.touched.companyName && validation.errors.companyName ? true : false
                                                        }
                                                    />
                                                    {validation.touched.companyName && validation.errors.companyName ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.companyName}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col md="12">

                                            </Col>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">ელ-ფოსტა</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>


                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">კომპანიის საიდენტიფიკაციაა</Label>
                                                    <Input
                                                        name="companyId"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.companyId || ""}
                                                        invalid={
                                                            validation.touched.companyId && validation.errors.companyId ? true : false
                                                        }
                                                    />
                                                    {validation.touched.companyId && validation.errors.companyId ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.companyId}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">კომპანის ტელეფონის ნომერი</Label>
                                                    <Input
                                                        name="phone"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone || ""}
                                                        invalid={
                                                            validation.touched.phone && validation.errors.phone ? true : false
                                                        }
                                                    />
                                                    {validation.touched.phone && validation.errors.phone ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.phone}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">კომპანის მისამართი</Label>
                                                    <Input
                                                        name="address"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.address || ""}
                                                        invalid={
                                                            validation.touched.address && validation.errors.address ? true : false
                                                        }
                                                    />
                                                    {validation.touched.address && validation.errors.address ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.address}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col lg="6">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    დამახსოვრება
                                                </button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </CardBody>
                            </Card>

                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Add)
