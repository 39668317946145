import React from "react";
import {Col, Spinner} from "reactstrap";

const SpinnerPage = (error) =>{
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Col lg={12} className={'text-center'}>
                        <Spinner className="m-1" color="success" />
                    </Col>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SpinnerPage
