import {call, all, takeEvery, put} from "redux-saga/effects"
import {
    CREATE_COMPANY_REQUEST, DELETE_COMPANY_REQUEST, DELETE_COMPANY_REQUEST_SUCCESS,
    GET_COMPANY_DETAIL_REQUEST,
    GET_COMPANY_DETAIL_REQUEST_SUCCESS,
    GET_COMPANIES_REQUEST, GET_COMPANIES_REQUEST_SUCCESS,
    UPDATE_COMPANY_REQUEST, SEARCH_COMPANIES_REQUEST, SEARCH_COMPANIES_REQUEST_SUCCESS
} from "./actionTypes"

import {
    SuccessResult,
    ErrorResult, GlobalErrorResult
} from "../../components/Notifications/Messages"

import {getTokenFromLocalStorage} from "../../utils/Token";

function* createSaga({payload}) {
    try {
        const callBack = yield call(createSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
            // payload.history.push(`/partner-details/${callBack.result.data._id}`)
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const createSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getDetailSaga({payload}) {
    try {
        const callBack = yield call(getDetailSagaAsync, payload)
        if (callBack.success){
            yield put({ type: GET_COMPANY_DETAIL_REQUEST_SUCCESS, callBack })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const getDetailSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/getDetail/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    return await response.json()
}

function* updateSaga({payload}) {
    try {
        const callBack = yield call(updateSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const updateSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/update/${item.id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getsSaga({payload}) {
    try {
        const callBack = yield call(getsSagaAsync, payload)
        if (callBack.success){
            yield put({ type: GET_COMPANIES_REQUEST_SUCCESS, callBack })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const getsSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/getAll/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    return await response.json()
}


function* deleteSaga({payload}) {
    try {
        const callBack = yield call(deleteSagaAsync, payload)
        if (callBack.success){
            yield put({ type: DELETE_COMPANY_REQUEST_SUCCESS, payload })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        console.error(e)
    }
}

const deleteSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/delete`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* searchSaga({payload}) {
    try {
        const callBack = yield call(searchSagaAsync, payload)
        if (callBack.success){
            console.log("########",callBack)
            yield put({ type: SEARCH_COMPANIES_REQUEST_SUCCESS, callBack })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const searchSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/company/search/${item.string}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    return await response.json()
}
function* rootSaga() {
    try {
        yield all([
            yield takeEvery(CREATE_COMPANY_REQUEST, createSaga),
            yield takeEvery(GET_COMPANY_DETAIL_REQUEST, getDetailSaga),
            yield takeEvery(UPDATE_COMPANY_REQUEST, updateSaga),
            yield takeEvery(GET_COMPANIES_REQUEST, getsSaga),
            yield takeEvery(DELETE_COMPANY_REQUEST, deleteSaga),
            yield takeEvery(SEARCH_COMPANIES_REQUEST, searchSaga)
        ]);
    } catch (error) {
        GlobalErrorResult('error')
    }
}

export default rootSaga;
