import {
    GET_ALL_INFORMATION_REQUEST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
    info: {
        AWB: 0,
        company: 0,
        confirmed: 0,
        courier: 0,
        declaration: 0,
        domesticShipping: 0,
        instruction: 0,
        organizeLoad: 0,
        reject: 0,
        stock: 0,
        tariffIssued: 0,
        waiting: 0,
        archive: 0,
    }
}

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_INFORMATION_REQUEST_SUCCESS:
            return {
                ...state,
                info : action.callBack.info
            }


        default:
            return state
    }
}

export default reducer
