import {call, put, takeEvery} from "redux-saga/effects"
import {
    ADD_FILES_REQUEST, DELETE_FILE_REQUEST, DELETE_FILE_REQUEST_SUCCESS, GET_FILES_REQUEST, GET_FILES_REQUEST_SUCCESS,
} from "./actionTypes"
import {errorAddPhotosNullPhotos, successAddPhotos} from "../../components/Notifications/Messages"

function* nulledPhotosSaga({payload}) {
    try {
        const callBack = yield call(nulledPhotosSagaAsync, payload)
    } catch (err) {
        console.error(err)
    }
}

const nulledPhotosSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/nulledPhotos`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    return await response.json()
}


function* nulledUserPhotosSaga({payload}) {
    try {
        const callBack = yield call(nulledUserPhotosSagaAsync, payload)
    } catch (err) {
        console.error(err)
    }
}

const nulledUserPhotosSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/nulledUserPhotos`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getPhotosSaga({payload}) {
    try {
        const callBack = yield call(getPhotosSagaAsync, payload)
        if (callBack.success) {
            yield put({type: GET_FILES_REQUEST_SUCCESS, callBack})
        } else {

        }

    } catch (err) {
        console.error(err)
    }
}

const getPhotosSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/getAll/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    return await response.json()
}


function* deletePhotoSaga({payload}) {
    try {

        const callBack = yield call(deletePhotoSagaAsync, payload)
        if (callBack.success) {
            yield put({type: DELETE_FILE_REQUEST_SUCCESS, payload})
        } else {

        }

    } catch (err) {
        console.error(err)
    }
}


const deletePhotoSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/delete`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* addPhotosSaga({payload}) {
    try {
        const callBack = yield call(addPhotosSagaAsync, payload)
        if (callBack.success) {
            successAddPhotos("success")
            let item = {
                id : payload.objId
            }
            console.log("####",payload)
             const callBack = yield call(getPhotosSagaAsync, item)
            if (callBack.success) {
                yield put({type: GET_FILES_REQUEST_SUCCESS, callBack})
            }
        } else {
            if (callBack.message === "upload_photos") {
                errorAddPhotosNullPhotos("error")
            }
        }

    } catch (err) {
        console.error(err)
    }
}

const addPhotosSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/upload/addFiles`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* authSaga() {
    yield takeEvery(GET_FILES_REQUEST, getPhotosSaga)
    yield takeEvery(DELETE_FILE_REQUEST, deletePhotoSaga)
    yield takeEvery(ADD_FILES_REQUEST, addPhotosSaga)
}

export default authSaga
