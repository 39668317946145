import {
    CREATE_QUESTION_REQUEST, GET_LOGISTIC_DETAIL_REQUEST, GET_LOGISTICS_AWB_REQUEST, GET_LOGISTICS_REQUEST,
    UPDATE_LOGISTICS_REQUEST,
    DELETE_LOGISTICS_REQUEST,
    GET_SEARCH_LOGISTICS_REQUEST
} from "./actionTypes";
export const createQuestion = (data) => ({
    type: CREATE_QUESTION_REQUEST,
    payload :data
})

export const getLogisticDetail = (data) => ({
    type: GET_LOGISTIC_DETAIL_REQUEST,
    payload :data
})

export const getLogistics = (data) => ({
    type: GET_LOGISTICS_REQUEST,
    payload :data
})

export const searchLogistics = (data) => ({
    type: GET_SEARCH_LOGISTICS_REQUEST,
    payload :data
})

export const getLogisticsAWB = (data) => ({
    type: GET_LOGISTICS_AWB_REQUEST,
    payload :data
})

export const updateLogistics = (data) => ({
    type: UPDATE_LOGISTICS_REQUEST,
    payload :data
})
export const deleteLogistic = (data) => ({
    type: DELETE_LOGISTICS_REQUEST,
    payload :data
})
