import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Card, CardBody, Col} from "reactstrap"
import withRouter from "../../components/Common/withRouter";
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {
    deleteAdmin, deleteCompany, deleteLogistic,
} from "../../store/actions";
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert";

import {logisticStatus} from '../../utils/Status'


const AWBColumns = props => {
    const dispatch = useDispatch();

    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteLogistic(item))
        setconfirm_alert(false)
    }



    const Columns = [
        {
            dataField: "status",
            text: "სტატუსი",
            formatter: (cellContent, row, key) => (
                <>
                    {logisticStatus(row.status)}
                </>
            ),
        },

        {
            dataField: "transporterName",
            text: "გადამზიდი",
            sort: true,
        },
        {
            dataField: "sellerName",
            text: "გაყიდვები",
            sort: true,
        },

        {
            dataField: "_id",
            text: "ტვირთის Id",
            sort: true,
        },

        {
            dataField: "primary.primaryTracking",
            text: "პრ. თრექინგი",
            sort: true,
        },
        {
            dataField: "primary.primaryGrossWeight",
            text: "პრ. ბრუტო წონა",
            sort: true,
        },
        {
            dataField: "primary.primaryVolumeWeight",
            text: "პრ.მოცულობითი წონა",
            sort: true,
        },
        {
            dataField: "primary.primaryNumberOfSeat",
            text: "პრ. ადგილების რაოდენობა",
            sort: true,
        },

        {
            dataField: "last.lastGrossWeight",
            text: "საბოლოო ბრუტო წონა",
            sort: true,
        },

        {
            dataField: "last.lastVolumeWeight",
            text: "საბოლოო მოცულობითი წონა",
            sort: true,
        },
        {
            dataField: "last.lastNumberOfSeat",
            text: "საბოლოო ადგილების წონა",
            sort: true,
        },


        {
            dataField: "productPrice",
            text: "ფასი",
            sort: true,
        },
        {
            dataField: "company.phone",
            text: "საკონტაქტო ნომერი",
            sort: true,
        },

        {
            dataField: "company.companyName",
            text: "კომპანიის სახელი",
            sort: true,
        },

        {
            dataField: "company.companyId",
            text: "საიდენტიფიკაციო კოდი",
            sort: true,
        },


        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <Link to={`/detail-logistic/${row._id}`}
                          className="px-3 text-primary"><FeatherIcon icon="edit"
                                                                     className="icon-xs icon me-2 text-pending"/>
                    </Link>
                    <span>
                              <FeatherIcon
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                      handleOpenAlertModal(row._id, key)
                                  }}
                                  icon="trash" className="icon-xs icon me-2 text-danger"/>
                     </span>
                </>
            ),
        },

    ]


    return (
        <>
            <BootstrapTable
                keyField="_id"
                data={props.array}
                columns={Columns}
            />
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        style={{backgroundColor: '#404451'}}
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default withRouter(AWBColumns);
