import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createAdmin, getAdmins, getUsers} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import {notification} from "antd";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Nulled from "../../components/Null/Nulled";

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ახალი ადმინის დასამატებლად შეავსეთ ყველა ველი',
    });
};


const Index = props => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.Admin.isLoading);
    const error = useSelector((state) => state.Admin.error);
    const array = useSelector((state) => state.Admin.array);

    const candidateRole = useSelector((state) => state.Authorisation.candidate.role);
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')

    useEffect(() => {
         dispatch(getAdmins())
    }, [])

    const handleAddAdmin = () => {
        if (name, phone, login, password, role) {
            let item = {
                name,
                phone,
                login,
                password,
                role,
            }
            dispatch(createAdmin(item))
        } else {
            modalError('error');
        }

    }

    return (
        <React.Fragment>
            {
                candidateRole === 'admin' &&   <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="სია" breadcrumbItem="ადმინები"/>
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <Col md="12">
                                                <h4>ახალი ადმინის დამატება</h4>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">სახელი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">ლოგინი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={login}
                                                        onChange={(e) => setLogin(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">პაროლი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        name="password"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">როლი</Label>
                                                    <select
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        name="role"
                                                        className="form-control">
                                                        <option value="">--</option>
                                                        <option value="admin">Admin</option>
                                                        <option value="sell">Sell</option>
                                                        <option value="logistic">Logistic</option>
                                                        <option value="declarant">Declarant</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" style={{paddingTop: '27px'}}>

                                                <Button
                                                    onClick={handleAddAdmin}
                                                    color="primary"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    დამატება
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        {isLoading ? (
                            <Preloader/>
                        ) : error ? (
                            <Error/>
                        ) : (
                            <Row>
                                {
                                    array.length > 0
                                        ? <Col lg={12}>
                                            <ObjectColumns
                                                array={array}
                                            />
                                        </Col>
                                        : <Nulled/>
                                }
                            </Row>
                        )}

                    </div>
                </div>

            }



        </React.Fragment>

    )
}

export default withRouter(Index);
