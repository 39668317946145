import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_ADMIN_REQUEST,
    DELETE_ADMIN_REQUEST, DELETE_ADMIN_REQUEST_SUCCESS, GET_ADMIN_DETAIL_REQUEST, GET_ADMIN_DETAIL_REQUEST_SUCCESS,
    GET_ADMIN_REQUEST, GET_ADMIN_REQUEST_SUCCESS, UPDATE_ADMIN_PASSWORD_REQUEST, UPDATE_ADMIN_REQUEST,
} from "./actionTypes";
import {notification} from "antd";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";
import {getTokenFromLocalStorage} from "../../utils/Token";
import {updateAdminPassword} from "./actions";

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};

const modalErrorLogin = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ასეთი მომხარებელი არსეობს ბაზაში',
    });
};


function* getAdminsSaga({payload}) {
    try {
        const callBack = yield call(getAdminsSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_ADMIN_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const getAdminsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/list`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}



function* deleteAdminSaga({payload}) {
    try {
        const callBack = yield call(deleteAdminSagaAsync, payload)
        if (callBack) {
            modalSuccess('success');
            yield put({type: DELETE_ADMIN_REQUEST_SUCCESS, payload})
        }else {
            modalError('error');
        }
    } catch (e) {
        console.error(e);
    }
}

const deleteAdminSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/delete`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}
function* createAdminSaga({payload}) {
    try {
        const callBack = yield call(createAdminSagaAsync, payload)
        if (callBack.success) {
            modalSuccess('success');
            yield put({type: GET_ADMIN_REQUEST})
        }else {
            if (callBack.message === 'email_or_user_name_already_registred'){
                modalErrorLogin('error');
            }else {
                modalError('error');
            }

        }
    } catch (e) {
        console.error(e);
    }
}

const createAdminSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body :JSON.stringify(item)
    })
    return await response.json()
}


function* getDetailSaga({payload}) {
    try {
        const callBack = yield call(getDetailSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_ADMIN_DETAIL_REQUEST_SUCCESS, callBack})
        }else {
            ErrorResult('error')
        }

    } catch (e) {
        console.error(e);
    }
}

const getDetailSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/getDetail/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        }
    })
    return await response.json()
}




function* updateSaga({payload}) {
    try {
        const callBack = yield call(updateSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const updateSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/update/${item.id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* updateAdminPasswordSaga({payload}) {
    try {
        const callBack = yield call(updateAdminPasswordSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const updateAdminPasswordSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/admin/updatePassword/${item.id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}



function* productsSaga() {
    yield takeEvery(GET_ADMIN_REQUEST, getAdminsSaga)
    yield takeEvery(DELETE_ADMIN_REQUEST, deleteAdminSaga)
    yield takeEvery(CREATE_ADMIN_REQUEST, createAdminSaga)
    yield takeEvery(GET_ADMIN_DETAIL_REQUEST, getDetailSaga)
    yield takeEvery(UPDATE_ADMIN_REQUEST, updateSaga)
    yield takeEvery(UPDATE_ADMIN_PASSWORD_REQUEST, updateAdminPasswordSaga)
}
export default productsSaga
