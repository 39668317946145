import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback, CardBody,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {notification} from 'antd';
import {useFormik} from "formik"
import * as Yup from "yup"


import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Select from "react-select";


const ChangePassword = (props) => {

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            newPassword: '',

        },
        validationSchema: Yup.object({
            newPassword: Yup.string().required("შეიყვანეთ ახალი პაროლი"),
        }),
        onSubmit: (values) => {
            let item = {
                newPassword: values.newPassword,
            }
            changePassword(item)
        }
    })


    async function changePassword(item) {
        let jwt = localStorage.getItem("token")
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/auth/changePassword`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(item)
            });
            const Data = await response.json();

            if (!Data.success) {

            } else {
                SuccessResult('success')
            }

        } catch (error) {
            throw error;
        } finally {

        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="პაროლის შეცვლა"/>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <Form
                                        className="form-horizontal"
                                        onSubmit={(e) => {
                                            e.preventDefault()
                                            validation.handleSubmit()
                                            return false
                                        }}
                                    >

                                        <Row>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label className="form-label">ახალი პაროლი</Label>
                                                    <Input
                                                        name="newPassword"
                                                        className="form-control"
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.newPassword || ""}
                                                        invalid={
                                                            validation.touched.newPassword && validation.errors.newPassword ? true : false
                                                        }
                                                    />
                                                    {validation.touched.newPassword && validation.errors.newPassword ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <button
                                                    className="btn btn-primary w-100 waves-effect waves-light"
                                                    type="submit"
                                                >
                                                    პაროლის შეცვლა
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )


}

export default ChangePassword

