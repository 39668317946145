import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    FormFeedback, CardTitle, CardImg, InputGroup
} from "reactstrap"
import moment from "moment";

import {useParams} from 'react-router-dom';
import classnames from "classnames"
//Import Breadcrumb
import {useFormik} from "formik"
import * as Yup from "yup"
import FormUpload from "../../components/Uploader/FormUpload";
import Breadcrumbs from "../../components/Common/Breadcrumb"
import SpinnerPage from "../../components/SpinnerPage/SpinnerPage";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Remove from '../../assets/images/remove.png'
import PDF_file_icon from '../../assets/images/PDF_file_icon.svg.png'
import {getLogisticDetail, updateLogistics} from "../../store/Logistic/actions";
import {getAdmins} from "../../store/Admin/actions";
import {addFile, deleteFile, getFiles} from "../../store/Files/actions";
import {getAWBes} from "../../store/AWB/actions";
import Authorisation from "../../store/Authorisation/reducer";
import Flatpickr from "react-flatpickr";
import {getAllInformation} from "../../store/Information/actions";

const Logistic = (props) => {
    const dispatch = useDispatch();
    const {id} = useParams();
    const [activeTab, setactiveTab] = useState(1)
    const [awbIdName, setAwbIdName] = useState(1)

    const isAuthenticated = useSelector((state) => state.Authorisation.isAuthenticated);
    const candidateRole = useSelector((state) => state.Authorisation.candidate.role);
    const arrayAdmin = useSelector((state) => state.Admin.array);

    const isLoading = useSelector((state) => state.Logistic.isLoading);
    const error = useSelector((state) => state.Logistic.error);
    const detail = useSelector((state) => state.Logistic.detail);

    const awbIsLoading = useSelector((state) => state.AWB.isLoading);
    const awbError = useSelector((state) => state.AWB.error);
    const awbArray = useSelector((state) => state.AWB.array);

    const arrayFiles = useSelector((state) => state.Files.array);

    const [btnActive, setBtnActiveStatus] = useState(true)
    useEffect(() => {
        if (validation.values.awbId) {
            const foundItem = awbArray.find(item => item._id === validation.values.awbId);
            if (foundItem) {
                setAwbIdName(foundItem.title);

                if (foundItem.status === 'archive') {
                    setBtnActiveStatus(false)
                }
            }

        }

    }, [awbArray])


    useEffect(() => {
        dispatch(getAdmins())

        let item = {
            skip: 0,
            limit: 10000,
            status: 'all'
        }
        dispatch(getAWBes(item))

    }, [])


    useEffect(() => {
        let item = {
            id
        }

        dispatch(getLogisticDetail(item))
        dispatch(getFiles(item))
        /*        dispatch(nulledPhotos())

                dispatch(getRentCarDetails(item))*/
    }, [])



    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            _id: detail._id || '',
            senderCountry: detail.senderCountry || '',
            receiverCountry: detail.receiverCountry || '',
            typeOfTransport: detail.typeOfTransport || '',
            typeOfShipment: detail.typeOfShipment || '',
            senderCondition: detail.senderCondition || '',
            receiverCondition: detail.receiverCondition || '',
            productName: detail.productName || '',
            takeAddress: detail.takeAddress || '',
            additionalDescription: detail.additionalDescription || '',

            productPrice: detail.productPrice || '',
            sender: detail.sender || '',
            takeTheCargo: detail.takeTheCargo || '',
            arrivedToTheWarehouse: detail.arrivedToTheWarehouse || '',

            primaryGrossWeight: detail.primary?.primaryGrossWeight || '',
            primaryCubicWeight: detail.primary?.primaryCubicWeight || '',
            primaryNumberOfSeat: detail.primary?.primaryNumberOfSeat || '',
            primarySize: detail.primary?.primarySize || '',
            primaryVolumeWeight: detail.primary?.primaryVolumeWeight || '',
            primaryTracking: detail.primary?.primaryTracking || '',
            primaryRatePerKilogram: detail.primary?.primaryRatePerKilogram || '',
            primaryTotalTariff: detail.primary?.primaryTotalTariff || '',
            primaryProfit: detail.primary?.primaryProfit || '',

            lastGrossWeight: detail.last?.lastGrossWeight || '',
            lastCubicWeight: detail.last?.lastCubicWeight || '',
            lastNumberOfSeat: detail.last?.lastNumberOfSeat || '',
            lastSize: detail.last?.lastSize || '',
            lastVolumeWeight: detail.last?.lastVolumeWeight || '',
            lastTracking: detail.last?.lastTracking || '',
            lastRatePerKilogram: detail.last?.lastRatePerKilogram || '',
            lastTotalTariff: detail.last?.lastTotalTariff || '',
            lastProfit: detail.last?.lastProfit || '',

            insurance: detail.insurance || '',
            awbId: detail.awbId || '',
            awbName: detail.awbName || '',
            additionalCommentary: detail.additionalCommentary || '',

            declarationStatus: detail.declarationStatus || '',
            transportInvoice: detail.transportInvoice || '',
            courierStatus: detail.courierStatus || '',
            productGeorgianName: detail.productGeorgianName || '',
            paymentDate: detail.paymentDate || '',

            transporterId: detail?.transporterId || '',
            sellerId: detail?.sellerId || '',
            status: detail.status || '',
        },

        validationSchema: Yup.object(),
        onSubmit: (values) => {
            let item = {
                id: id,
                // values: values,
                senderCountry: values.senderCountry,
                receiverCountry: values.receiverCountry,
                typeOfTransport: values.typeOfTransport,
                typeOfShipment: values.typeOfShipment,
                senderCondition: values.senderCondition,
                receiverCondition: values.receiverCondition,
                productName: values.productName,
                takeAddress: values.takeAddress,
                additionalDescription: values.additionalDescription,
                productPrice: values.productPrice,
                sender: values.sender,
                takeTheCargo: values.takeTheCargo,
                arrivedToTheWarehouse: values.arrivedToTheWarehouse,
                primary: {
                    primaryGrossWeight: values.primaryGrossWeight,
                    primaryCubicWeight: values.primaryCubicWeight,
                    primaryNumberOfSeat: values.primaryNumberOfSeat,
                    primarySize: values.primarySize,
                    primaryVolumeWeight: values.primaryVolumeWeight,
                    primaryTracking: values.primaryTracking,
                    primaryRatePerKilogram: values.primaryRatePerKilogram,
                    primaryTotalTariff: values.primaryTotalTariff,
                    primaryProfit: values.primaryProfit,
                },
                last: {
                    lastGrossWeight: values.lastGrossWeight,
                    lastCubicWeight: values.lastCubicWeight,
                    lastNumberOfSeat: values.lastNumberOfSeat,
                    lastSize: values.lastSize,
                    lastVolumeWeight: values.lastVolumeWeight,
                    lastTracking: values.lastTracking,
                    lastRatePerKilogram: values.lastRatePerKilogram,
                    lastTotalTariff: values.lastTotalTariff,
                    lastProfit: values.lastProfit,
                },

                insurance: values.insurance,
                additionalCommentary: values.additionalCommentary,

                declarationStatus: values.declarationStatus,
                transportInvoice: values.transportInvoice,
                courierStatus: values.courierStatus,
                productGeorgianName: values.productGeorgianName,
                paymentDate: values.paymentDate,

                transporterId: values.transporterId,
                sellerId: values.sellerId,
                awbId: values.awbId,
                status: values.status,
                activeTab
            }
             dispatch(updateLogistics(item))
             dispatch(getAllInformation())
        }
    })

    const handleUpload = (type) => {
        let item = {
            objId: detail._id,
            type: type
        }
        dispatch(addFile(item))

        let item2 = {
            id
        }
        dispatch(getFiles(item2))
    }

    const handleRemove = (id) => {
        let item = {
            id: id
        }
        dispatch(deleteFile(item))
    }

    if (isLoading) {
        return <SpinnerPage/>
    }

    if (error) {
        return <ErrorPage error={error}/>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rental Car Detail"/>
                    {
                        (detail._id && isAuthenticated)
                            ?
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <div className="wizard clearfix">

                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({current: activeTab === 1})}>
                                                            <NavLink
                                                                className={classnames({current: activeTab === 1})}
                                                                onClick={() => {
                                                                    setactiveTab(1)
                                                                }}
                                                            >
                                                                <span className="number">1.</span>{" "} შეკითხვა
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({current: activeTab === 2})}>
                                                            <NavLink
                                                                className={classnames({active: activeTab === 2})}
                                                                onClick={() => {
                                                                    setactiveTab(2)
                                                                }}
                                                            >
                                                                <span className="number me-2">2.</span>{" "} წონები
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({current: activeTab === 3})}>
                                                            <NavLink
                                                                className={classnames({active: activeTab === 3})}
                                                                onClick={() => {
                                                                    setactiveTab(3)
                                                                }}
                                                            >
                                                                <span className="number me-2">3.</span>{" "} კომპანია
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className={classnames({current: activeTab === 4})}>
                                                            <NavLink
                                                                className={classnames({active: activeTab === 4})}
                                                                onClick={() => {
                                                                    setactiveTab(4)
                                                                }}
                                                            >
                                                                <span className="number me-2">4.</span>{" "}
                                                                ფაილის ატვირთვა
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem className={classnames({current: activeTab === 5})}>
                                                            <NavLink
                                                                className={classnames({active: activeTab === 5})}
                                                                onClick={() => {
                                                                    setactiveTab(5)
                                                                }}
                                                            >
                                                                <span className="number me-2">5.</span>{" "}
                                                                დეკლარაციები
                                                            </NavLink>
                                                        </NavItem>

                                                        <NavItem className={classnames({current: activeTab === 6})}>
                                                            <NavLink
                                                                className={classnames({active: activeTab === 6})}
                                                                onClick={() => {
                                                                    setactiveTab(6)
                                                                }}
                                                            >
                                                                <span className="number me-2">6.</span>{" "}
                                                                სტატუსი
                                                            </NavLink>
                                                        </NavItem>


                                                    </ul>
                                                </div>


                                                <div className="content clearfix">
                                                    <div className="body">
                                                        <TabContent
                                                            activeTab={activeTab}
                                                        >
                                                            <TabPane tabId={1}>
                                                                <Form
                                                                    className="form-horizontal"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault()
                                                                        validation.handleSubmit()
                                                                        return false
                                                                    }}
                                                                >

                                                                    <Row>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">გამომგზავნი
                                                                                    ქვეყანა</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="senderCountry"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.senderCountry || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="georgia">საქართველო
                                                                                    </option>
                                                                                    <option value="china">ჩინეთი
                                                                                    </option>
                                                                                    <option value="usa">ამერიკა</option>
                                                                                    <option value="korea">კორეა</option>
                                                                                    <option value="bulgaria">ბულგარეთი
                                                                                    </option>
                                                                                    <option
                                                                                        value="greatBritain">ბრიტანეთი
                                                                                    </option>
                                                                                    <option value="hungary">უნგრეთი
                                                                                    </option>
                                                                                    <option value="poland">პოლონეთი
                                                                                    </option>
                                                                                    <option value="italy">იტალია
                                                                                    </option>
                                                                                    <option value="france">საფრანგეთი
                                                                                    </option>
                                                                                    <option value="taiwan">ტაივანი
                                                                                    </option>
                                                                                    <option value="japan">იაპონია
                                                                                    </option>
                                                                                    <option value="turkey">თურქეთი
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">მიმღები ქვეყანა</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="receiverCountry"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.receiverCountry || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="georgia">საქართველო
                                                                                    </option>
                                                                                    <option value="china">ჩინეთი
                                                                                    </option>
                                                                                    <option value="usa">ამერიკა</option>
                                                                                    <option value="korea">კორეა</option>
                                                                                    <option value="bulgaria">ბულგარეთი
                                                                                    </option>
                                                                                    <option
                                                                                        value="greatBritain">ბრიტანეთი
                                                                                    </option>
                                                                                    <option value="hungary">უნგრეთი
                                                                                    </option>
                                                                                    <option value="poland">პოლონეთი
                                                                                    </option>
                                                                                    <option value="italy">იტალია
                                                                                    </option>
                                                                                    <option value="france">საფრანგეთი
                                                                                    </option>
                                                                                    <option value="taiwan">ტაივანი
                                                                                    </option>
                                                                                    <option value="japan">იაპონია
                                                                                    </option>
                                                                                    <option value="turkey">თურქეთი
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">ტრანსპორტირება</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="typeOfTransport"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.typeOfTransport || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="aer">საჰაერო</option>
                                                                                    <option value="sea">საზღვაო</option>
                                                                                    <option value="land">სახმელეთო
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">გადაზიდვის ტიპი</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="typeOfShipment"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.typeOfShipment || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="import">იმპორტი
                                                                                    </option>
                                                                                    <option value="export">ექსპორტი
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>


                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">გამოგზავნის  პირობა</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="senderCondition"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.senderCondition || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="EXW">EXW</option>
                                                                                    <option value="FCA">FCA</option>
                                                                                    <option value="FOB">FOB</option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">მიღების პირობა</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="receiverCondition"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.receiverCondition || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="door">კარამდე
                                                                                    </option>
                                                                                    <option value="warehouse">საწყობამდე
                                                                                    </option>
                                                                                    <option value="airport">აეროპორტამდე
                                                                                    </option>
                                                                                    <option
                                                                                        value="airportPreliminary"> აეროპორტი
                                                                                        (წინასწარი)
                                                                                    </option>


                                                                                </select>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პროდუქტის დასახელება</Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="productName"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.productName || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="8">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label"> აღების
                                                                                    მისამართი </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="takeAddress"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.takeAddress || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="12">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label"> დამატებითი
                                                                                    ინფორმაცია </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="additionalDescription"
                                                                                    className="form-control"
                                                                                    type="textarea"
                                                                                    rows="4"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.additionalDescription || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        btnActive &&  <Row>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <button
                                                                                        disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                                                        type="submit"
                                                                                    >
                                                                                        დამახსოვრება
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    }


                                                                </Form>

                                                            </TabPane>

                                                            <TabPane tabId={2}>
                                                                <Form
                                                                    className="form-horizontal"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault()
                                                                        validation.handleSubmit()
                                                                        return false
                                                                    }}
                                                                >


                                                                    <Row>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პროდუქციის
                                                                                    ფასი</Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="productPrice"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.productPrice || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">გამომგზავნი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="sender"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.sender || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">დაზღვევა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'sell')}
                                                                                    name="insurance"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.insurance || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label"> ტვირთის
                                                                                    id</Label>
                                                                                <Input
                                                                                    disabled={true}
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    value={detail._id || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <div className="mb-3">
                                                                                <h3>პირველადი წონა</h3>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label"> ბრუტო
                                                                                    წონა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryGrossWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryGrossWeight || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">კუბატურა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryCubicWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryCubicWeight || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">ადგილების
                                                                                    რაოდენობა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryNumberOfSeat"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryNumberOfSeat || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">ზომები </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primarySize"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primarySize || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">მოცულობითი
                                                                                    წონა
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryVolumeWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryVolumeWeight || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პირველადი
                                                                                    თრექინგი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryTracking"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryTracking || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პირველადი
                                                                                    ტარიფი კილოგრამზე
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryRatePerKilogram"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryRatePerKilogram || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პირველადი
                                                                                    ჯამური ტარიფი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryTotalTariff"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryTotalTariff || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">პირველადი
                                                                                    მოგება
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                    name="primaryProfit"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.primaryProfit || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="12">
                                                                            <hr/>
                                                                            <br/>
                                                                        </Col>


                                                                        <Col lg="5">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">ავიღეთ
                                                                                    ტვირთი </Label>

                                                                                <InputGroup>
                                                                                    <Flatpickr
                                                                                        className="form-control d-block"
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        options={{
                                                                                            altInput: true,
                                                                                            altFormat: "d/m/Y", // Display format
                                                                                            dateFormat: "Y-m-d", // Format sent to Formik
                                                                                        }}
                                                                                        value={validation.values.takeTheCargo}
                                                                                        onChange={(selectedDates, dateStr) => {
                                                                                            if (dateStr.length > 0) {
                                                                                                validation.setFieldValue('takeTheCargo', dateStr);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>

                                                                                {/*    <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="takeTheCargo"
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.takeTheCargo || ""}

                                                                                />*/}
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="5">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">მივიდა
                                                                                    საწყობში  </Label>
                                                                                <InputGroup>
                                                                                    <Flatpickr
                                                                                        className="form-control d-block"
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        options={{
                                                                                            altInput: true,
                                                                                            altFormat: "d/m/Y", // Display format
                                                                                            dateFormat: "Y-m-d", // Format sent to Formik
                                                                                        }}
                                                                                        value={validation.values.arrivedToTheWarehouse} // Устанавливаем значение
                                                                                        onChange={(selectedDates, dateStr) => {
                                                                                            // Обновляем значение в Formik при изменении даты
                                                                                            if (dateStr.length > 0) {
                                                                                                validation.setFieldValue('arrivedToTheWarehouse', dateStr);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>

                                                                            </div>
                                                                        </Col>
                                                                        {
                                                                            validation.values.awbName && <Col lg="2">
                                                                                <div className="mb-3 pt-3">
                                                                                    <Label
                                                                                        className="form-label">AWB:</Label>
                                                                                    <p> {validation.values.awbName}</p>
                                                                                </div>
                                                                            </Col>
                                                                        }


                                                                        <Col lg="12">
                                                                            <hr/>
                                                                            <br/>
                                                                        </Col>
                                                                        <Col lg="12">
                                                                            <div className="mb-3">
                                                                                <h3>საბოლოო წონა</h3>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label"> ბრუტო
                                                                                    წონა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastGrossWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastGrossWeight || ""}

                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">კუბატურა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastCubicWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastCubicWeight || ""}

                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">ადგილების
                                                                                    რაოდენობა </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastNumberOfSeat"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastNumberOfSeat || ""}

                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">ზომები </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastSize"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastSize || ""}

                                                                                />

                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">მოცულობითი
                                                                                    წონა
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastVolumeWeight"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastVolumeWeight || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">საბოლოო
                                                                                    თრექინგი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastTracking"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastTracking || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">საბოლო
                                                                                    ტარიფი კილოგრამზე
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastRatePerKilogram"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastRatePerKilogram || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">საბოლო
                                                                                    ჯამური ტარიფი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastTotalTariff"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastTotalTariff || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">საბოლო
                                                                                    მოგება
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="lastProfit"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lastProfit || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="12">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">კომენტარი
                                                                                </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="additionalCommentary"
                                                                                    className="form-control"
                                                                                    type="textarea"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.additionalCommentary || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {
                                                                        btnActive &&  <Row>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <button
                                                                                        disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic')}
                                                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                                                        type="submit"
                                                                                    >
                                                                                        დამახსოვრება
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    }


                                                                </Form>
                                                            </TabPane>
                                                            <TabPane tabId={3}>
                                                                <Row>
                                                                    <Col lg="4">

                                                                        <div className="mb-3">
                                                                            <Label className="form-label"> კომპანიის
                                                                                სახელი</Label>
                                                                            <Input
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={detail.company.companyName || ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="4">
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                className="form-label"> საიდენტიფიკაციო
                                                                                კოდი</Label>
                                                                            <Input
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={detail.company.companyId || ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="4">
                                                                        <div className="mb-3">
                                                                            <Label className="form-label"> CAG
                                                                                ID</Label>
                                                                            <Input
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={detail.company.cagId || ""}
                                                                            />
                                                                        </div>
                                                                    </Col><Col lg="4">
                                                                    <div className="mb-3">
                                                                        <Label className="form-label"> საკონტაქტო ნომერი
                                                                        </Label>
                                                                        <Input
                                                                            disabled={true}
                                                                            className="form-control"
                                                                            type="text"
                                                                            value={detail.company.phone || ""}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                    <Col lg="4">
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                className="form-label"> იმეილი</Label>
                                                                            <Input
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={detail.company.email || ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg="4">
                                                                        <div className="mb-3">
                                                                            <Label
                                                                                className="form-label"> მისამართი</Label>
                                                                            <Input
                                                                                disabled={true}
                                                                                className="form-control"
                                                                                type="text"
                                                                                value={detail.company.address || ""}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                            <TabPane tabId={4}>

                                                                {
                                                                    candidateRole !== 'declarant' &&
                                                                    <>
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <FormUpload
                                                                                        message={'საბუთები'}
                                                                                        objId={detail._id}
                                                                                        type={'invoice'}
                                                                                        status={'new'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            {
                                                                                arrayFiles.map((item, key) => {
                                                                                    if (item.type === 'invoice')
                                                                                        return (
                                                                                            <Col key={key} lg="1">
                                                                                                <a target="_blank"
                                                                                                   href={`${process.env.REACT_APP_UPLOADER_SERVER_URL}${item.url}`}>

                                                                                                    <Card
                                                                                                        className="mb-4">
                                                                                                        <div style={{
                                                                                                            position: 'absolute',
                                                                                                            padding: "10px",
                                                                                                            width: '100%'
                                                                                                        }}>

                                                                                                            <div
                                                                                                                style={{float: 'right'}}>

                                                                                                                <img
                                                                                                                    onClick={handleRemove.bind(this, item._id, key)}
                                                                                                                    style={{
                                                                                                                        height: '30px',
                                                                                                                        width: 'auto',
                                                                                                                        cursor: 'pointer'
                                                                                                                    }}
                                                                                                                    src={Remove}
                                                                                                                />


                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <CardImg top
                                                                                                                 className="img-fluid"
                                                                                                                 src={PDF_file_icon}
                                                                                                                 alt="Card image cap"/>
                                                                                                    </Card>
                                                                                                </a>
                                                                                                <i>{item.name}</i>
                                                                                            </Col>
                                                                                        )
                                                                                })
                                                                            }
                                                                            {
                                                                                btnActive &&   <Col lg="12">
                                                                                    <div className="mb-3 ">
                                                                                        <button
                                                                                            onClick={() => handleUpload('invoice')}
                                                                                            className="btn btn-primary w-100 waves-effect waves-light"
                                                                                            type="submit"
                                                                                        >
                                                                                            ატვირთვა
                                                                                        </button>
                                                                                    </div>

                                                                                </Col>
                                                                            }
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <hr/>
                                                                            </Col>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <FormUpload
                                                                                        message={'საბუთები გეზისთვის'}
                                                                                        objId={detail._id}
                                                                                        type={'transportInvoice'}
                                                                                        status={'new'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            {
                                                                                arrayFiles.map((item, key) => {
                                                                                    if (item.type === 'transportInvoice')
                                                                                        return (
                                                                                            <Col key={key} lg="1">
                                                                                                <a target="_blank"
                                                                                                   href={`${process.env.REACT_APP_UPLOADER_SERVER_URL}${item.url}`}>
                                                                                                    <Card
                                                                                                        className="mb-4">
                                                                                                        <div style={{
                                                                                                            position: 'absolute',
                                                                                                            padding: "10px",
                                                                                                            width: '100%'
                                                                                                        }}>

                                                                                                            <div
                                                                                                                style={{float: 'right'}}>
                                                                                                                <img
                                                                                                                    onClick={handleRemove.bind(this, item._id, key)}
                                                                                                                    style={{
                                                                                                                        height: '30px',
                                                                                                                        width: 'auto',
                                                                                                                        cursor: 'pointer'
                                                                                                                    }}
                                                                                                                    src={Remove}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <CardImg top
                                                                                                                 className="img-fluid"
                                                                                                                 src={PDF_file_icon}
                                                                                                                 alt="Card image cap"/>
                                                                                                    </Card>
                                                                                                    <i>{item.name}</i>
                                                                                                </a>
                                                                                            </Col>
                                                                                        )
                                                                                })
                                                                            }

                                                                            {
                                                                                btnActive &&  <Col lg="12">
                                                                                    <div className="mb-3 ">
                                                                                        <button
                                                                                            onClick={() => handleUpload('transportInvoice')}
                                                                                            className="btn btn-primary w-100 waves-effect waves-light"
                                                                                            type="submit"
                                                                                        >
                                                                                            ატვირთვა
                                                                                        </button>
                                                                                    </div>
                                                                                </Col>
                                                                            }

                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg="12">
                                                                                <hr/>
                                                                            </Col>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <FormUpload
                                                                                        message={'გამარტივებული დეკლარაცია'}
                                                                                        objId={detail._id}
                                                                                        type={'declaration'}
                                                                                        status={'new'}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            {
                                                                                arrayFiles.map((item, key) => {
                                                                                    if (item.type === 'declaration')
                                                                                        return (
                                                                                            <Col key={key} lg="1">
                                                                                                <Card className="mb-4">
                                                                                                    <div style={{
                                                                                                        position: 'absolute',
                                                                                                        padding: "10px",
                                                                                                        width: '100%'
                                                                                                    }}>

                                                                                                        <div
                                                                                                            style={{float: 'right'}}>
                                                                                                            <img
                                                                                                                onClick={handleRemove.bind(this, item._id, key)}
                                                                                                                style={{
                                                                                                                    height: '30px',
                                                                                                                    width: 'auto',
                                                                                                                    cursor: 'pointer'
                                                                                                                }}
                                                                                                                src={Remove}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <CardImg top
                                                                                                             className="img-fluid"
                                                                                                             src={PDF_file_icon}
                                                                                                             alt="Card image cap"/>
                                                                                                </Card>
                                                                                                <i>{item.name}</i>
                                                                                            </Col>
                                                                                        )
                                                                                })
                                                                            }

                                                                            {
                                                                                btnActive &&  <Col lg="12">
                                                                                    <div className="mb-3 ">
                                                                                        <button
                                                                                            onClick={() => handleUpload('declaration')}
                                                                                            className="btn btn-primary w-100 waves-effect waves-light"
                                                                                            type="submit"
                                                                                        >
                                                                                            ატვირთვა
                                                                                        </button>
                                                                                    </div>
                                                                                </Col>
                                                                            }

                                                                        </Row>

                                                                    </>
                                                                }

                                                            </TabPane>

                                                            <TabPane tabId={6}>
                                                                <Form
                                                                    className="form-horizontal"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault()
                                                                        validation.handleSubmit()
                                                                        return false
                                                                    }}
                                                                >

                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input1">
                                                                                    გადამზიდი
                                                                                </Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                    name="transporterId"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.transporterId || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    {
                                                                                        arrayAdmin.map((item, key) => {
                                                                                            if (item.role === 'logistic')
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={item._id}>{item.name}</option>
                                                                                                )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input1">
                                                                                    გაყიდვები
                                                                                </Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'sell')}
                                                                                    name="sellerId"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.sellerId || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    {
                                                                                        arrayAdmin.map((item, key) => {
                                                                                            if (item.role === 'sell')
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={item._id}>{item.name}</option>
                                                                                                )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input1">
                                                                                    სტატუსი
                                                                                </Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'declarant' || candidateRole === 'logistic')}
                                                                                    name="status"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.status || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>

                                                                                    {
                                                                                        (candidateRole === 'admin' || candidateRole === 'logistic') && <>
                                                                                            <option
                                                                                                value="waiting">მოლოდინის
                                                                                                რეჟიმი
                                                                                            </option>
                                                                                            <option
                                                                                                value="tariffIssued">ტარიფი
                                                                                                გაცემულია
                                                                                            </option>
                                                                                            <option
                                                                                                value="confirmed">დადასტრუდა
                                                                                            </option>
                                                                                            <option
                                                                                                value="reject">უარი
                                                                                            </option>
                                                                                            <option
                                                                                                value="organizeLoad">ტვირთის
                                                                                                ორგანიზება
                                                                                            </option>
                                                                                            <option
                                                                                                value="domesticShipping">შიდა
                                                                                                გადაზიდვა
                                                                                            </option>
                                                                                            <option
                                                                                                value="stock">საწყობშია
                                                                                            </option>
                                                                                            <option
                                                                                                value="AWB">AWB
                                                                                            </option>
                                                                                            <option
                                                                                                value="instruction">ინსტრუქცია
                                                                                            </option>
                                                                                            <option
                                                                                                value="declaration">დეკლარაცია
                                                                                            </option>
                                                                                            <option
                                                                                                value="courier">საკურიერო
                                                                                            </option>
                                                                                            <option
                                                                                                value="archive">არქივი
                                                                                            </option>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        (candidateRole === 'sell')
                                                                                        &&
                                                                                        <>
                                                                                            <option
                                                                                                value="waiting">მოლოდინის
                                                                                                რეჟიმი
                                                                                            </option>
                                                                                            <option
                                                                                                value="confirmed">დადასტრუდა
                                                                                            </option>
                                                                                            <option
                                                                                                value="reject">უარი
                                                                                            </option>
                                                                                        </>

                                                                                    }

                                                                                    {
                                                                                        candidateRole === 'declarant'
                                                                                        &&
                                                                                        <>
                                                                                            <option
                                                                                                value="declaration">დეკლარაცია
                                                                                            </option>
                                                                                            <option
                                                                                                value="courier">საკურიერო
                                                                                            </option>
                                                                                        </>
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <div className="mb-3">
                                                                                <Label for="basicpill-firstname-input1">
                                                                                    AWB ID
                                                                                </Label>
                                                                                {
                                                                                    awbArray.length > 0 &&
                                                                                    <select
                                                                                        disabled={!(candidateRole === 'admin' || candidateRole === 'logistic')}
                                                                                        name="awbId"
                                                                                        onChange={validation.handleChange}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={validation.values.awbId || ""}
                                                                                        className="form-control m-input">
                                                                                        <option value="">აირჩიეთ
                                                                                            ერთ-ერთი
                                                                                        </option>
                                                                                        {
                                                                                            awbArray.map((item, key) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={item._id}>{item.title}</option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                }

                                                                            </div>
                                                                        </Col>


                                                                    </Row>

                                                                    {
                                                                        btnActive &&      <Row>
                                                                            <Col lg="12">
                                                                                <div className="mb-3">
                                                                                    <button
                                                                                        disabled={!(candidateRole === 'admin' || candidateRole === 'sell' || candidateRole === 'logistic' || candidateRole === 'declarant')}
                                                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                                                        type="submit"
                                                                                    >
                                                                                        დამახსოვრება
                                                                                    </button>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>

                                                                    }

                                                                </Form>
                                                            </TabPane>
                                                            <TabPane tabId={5}>
                                                                <Form
                                                                    className="form-horizontal"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault()
                                                                        validation.handleSubmit()
                                                                        return false
                                                                    }}
                                                                >


                                                                    <Row>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <h3>დეკლარაცია</h3>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <h3>დავალიანება</h3>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <h3>საკურიერო</h3>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">დეკლარაციის
                                                                                    სტატუსი</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'declarant')}
                                                                                    name="declarationStatus"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.declarationStatus || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="TBA">TBA</option>
                                                                                    <option value="demo">დემო</option>
                                                                                    <option
                                                                                        value="mustPayGezi">გადასახდელი
                                                                                        აქვს გეზი
                                                                                    </option>
                                                                                    <option value="finish">დასრულდა
                                                                                    </option>
                                                                                    <option value="yellow">ყვითელი
                                                                                    </option>
                                                                                    <option value="red">წითელი</option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">სატრანსპორტო
                                                                                    ინვოისი</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'declarant')}
                                                                                    name="transportInvoice"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.transportInvoice || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="TBA">TBA</option>
                                                                                    <option
                                                                                        value="isPayable">გადასახდელია
                                                                                    </option>
                                                                                    <option value="paid">გადაიხადა
                                                                                    </option>
                                                                                    <option value="cognition">კონიგნაცია
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label">საკურიეროს
                                                                                    სტატუსი</Label>
                                                                                <select
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'declarant')}
                                                                                    name="courierStatus"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.courierStatus || ""}
                                                                                    className="form-control m-input">
                                                                                    <option value="">აირჩიეთ ერთ-ერთი
                                                                                    </option>
                                                                                    <option value="toSend">გასაგზავნი
                                                                                    </option>
                                                                                    <option value="current">მიმდინარე
                                                                                    </option>
                                                                                    <option value="delivered">მიტანილია
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label
                                                                                    className="form-label"> პროდუქციის
                                                                                    ქართული დასახელება </Label>
                                                                                <Input
                                                                                    disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'declarant')}
                                                                                    name="productGeorgianName"
                                                                                    className="form-control"
                                                                                    type="textarea"
                                                                                    rows="4"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.productGeorgianName || ""}
                                                                                />
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg="4">
                                                                            <div className="mb-3">
                                                                                <Label className="form-label">გადახდის
                                                                                    თარიღი </Label>
                                                                                <InputGroup>
                                                                                    <Flatpickr
                                                                                        className="form-control d-block"
                                                                                        placeholder="DD/MM/YYYY"
                                                                                        options={{
                                                                                            altInput: true,
                                                                                            altFormat: "d/m/Y", // Display format
                                                                                            dateFormat: "Y-m-d", // Format sent to Formik
                                                                                        }}
                                                                                        value={validation.values.paymentDate} // Устанавливаем значение
                                                                                        onChange={(selectedDates, dateStr) => {
                                                                                            // Обновляем значение в Formik при изменении даты
                                                                                            if (dateStr.length > 0) {
                                                                                                validation.setFieldValue('paymentDate', dateStr);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </InputGroup>

                                                                            </div>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            {
                                                                                btnActive &&  <div className="mb-3">
                                                                                    <button
                                                                                        disabled={!(candidateRole === 'admin' || candidateRole === 'logistic' || candidateRole === 'declarant')}

                                                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                                                        type="submit"
                                                                                    >
                                                                                        დამახსოვრება
                                                                                    </button>
                                                                                </div>
                                                                            }


                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Logistic
