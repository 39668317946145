import React, { useState } from "react"
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardTitle,
    Container
} from "reactstrap"
import DropzoneComponent from "react-dropzone-component"

// Breadcrumb
let token = localStorage.getItem("token")


const callbackArray = [(aa) => console.log("Hi!"), () => console.log("Ho!")]

const FormUpload = (props) => {

    const config = {
        iconFiletypes: [".jpg", ".png", ".jpeg", ".pdf"],
        showFiletypeIcon: false,
        postUrl: `${process.env.REACT_APP_SERVER_URL}/api2/upload/files/${props.objId}/${props.type}`
    }

    const dropzone = null

    const djsConfig = {
        addRemoveLinks: false,
        dictDefaultMessage: 'ატვირთეთ ფაილები: PDF, Excel, Word, TXT ან PNG',
        dictMaxFilesExceeded: "You can upload 6 photos only at a time",
        dictResponseError: "The photo is too large to upload!",
        // acceptedFiles: "application/pdf",
        acceptedFiles: '.pdf,.xlsx,.xls,.doc,.docx,.txt,.png,.jpg',
        maxFiles: "12",
    }

    const removedfile = () => {
    }

    const success = () => {
        // console.log("!!!success")
    }

    const callback = (e) => {
        //console.log("callback", e)
    }
    const callbackArray = [
        function () {
            console.log('Look Ma, I\'m a callback in an array!');
        },
        function () {
            console.log('Wooooow!');
        }
    ];
    const eventHandlers = {
        init: null,
        drop: callbackArray,
        addedfile: callback,
        success: success,
        removedfile: removedfile
    }

    return (
        <React.Fragment>
            <CardTitle className="h4">{props.message}</CardTitle>
            <DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} />
        </React.Fragment>
    )
}

export default FormUpload
