import {call, put, takeEvery} from "redux-saga/effects";
import {
    CREATE_QUESTION_REQUEST,
    DELETE_LOGISTICS_REQUEST,
    DELETE_LOGISTICS_REQUEST_SUCCESS,
    GET_LOGISTIC_DETAIL_REQUEST,
    GET_LOGISTIC_DETAIL_REQUEST_SUCCESS,
    GET_LOGISTICS_AWB_REQUEST,
    GET_LOGISTICS_AWB_REQUEST_SUCCESS,
    GET_LOGISTICS_REQUEST,
    GET_LOGISTICS_REQUEST_SUCCESS,
    GET_SEARCH_LOGISTICS_REQUEST,
    GET_SEARCH_LOGISTICS_REQUEST_SUCCESS,
    UPDATE_LOGISTICS_REQUEST,
} from "./actionTypes";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";
import {getTokenFromLocalStorage} from "../../utils/Token";
import {DELETE_COMPANY_REQUEST_SUCCESS} from "../Company/actionTypes";

function* createQuestionSaga({payload}) {
    try {
        const callBack = yield call(createQuestionSagaAsync, payload)
        if (callBack.success) {
            SuccessResult('success')
            payload.history(`/detail-logistic/${callBack.result._id}`)
        } else {
            ErrorResult('error')
        }
    } catch (e) {
        console.error(e);
    }
}

const createQuestionSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/createQuestion`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getLogisticDetailSaga({payload}) {
    try {
        const callBack = yield call(getLogisticDetailSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_LOGISTIC_DETAIL_REQUEST_SUCCESS, callBack})
        }else {
            ErrorResult('error')
        }

    } catch (e) {
        console.error(e);
    }
}

const getLogisticDetailSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/getDetail/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        }
    })
    return await response.json()
}


function* getAllSaga({payload}) {
    try {
        const callBack = yield call(getAllSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_LOGISTICS_REQUEST_SUCCESS, callBack})
        }else {
            ErrorResult('error')
        }

    } catch (e) {
        console.error(e);
    }
}

const getAllSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/getAll/${item.status}/${item.skip}/${item.limit}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        }
    })
    return await response.json()
}

function* getAllAWBSaga({payload}) {
    try {
        const callBack = yield call(getAllAWBSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_LOGISTICS_AWB_REQUEST_SUCCESS, callBack})
        }else {
            ErrorResult('error')
        }

    } catch (e) {
        console.error(e);
    }
}

const getAllAWBSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/getAllAwb/${item.awbId}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        }
    })
    return await response.json()
}


function* updateSaga({payload}) {
    try {
        const callBack = yield call(updateSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const updateSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/update/${item.id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* deleteSaga({payload}) {
    try {
        const callBack = yield call(deleteSagaAsync, payload)
        if (callBack.success){
            yield put({ type: DELETE_LOGISTICS_REQUEST_SUCCESS, payload })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        console.error(e)
    }
}

const deleteSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/delete`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}



function* searcheSaga({payload}) {
    try {
        const callBack = yield call(searcheSagaAsync, payload)
        if (callBack) {
            yield put({type: GET_SEARCH_LOGISTICS_REQUEST_SUCCESS, callBack})
        }else {
            ErrorResult('error')
        }

    } catch (e) {
        console.error(e);
    }
}

const searcheSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/search/${item.string}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`,
        }
    })
    return await response.json()
}



function* productsSaga() {
    yield takeEvery(CREATE_QUESTION_REQUEST, createQuestionSaga)
    yield takeEvery(GET_LOGISTIC_DETAIL_REQUEST, getLogisticDetailSaga)
    yield takeEvery(GET_LOGISTICS_REQUEST, getAllSaga)
    yield takeEvery(GET_LOGISTICS_AWB_REQUEST, getAllAWBSaga)
    yield takeEvery(UPDATE_LOGISTICS_REQUEST, updateSaga)
    yield takeEvery(DELETE_LOGISTICS_REQUEST, deleteSaga)
    yield takeEvery(GET_SEARCH_LOGISTICS_REQUEST, searcheSaga)
}

export default productsSaga
