export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST"
export const GET_ADMIN_REQUEST_SUCCESS = "GET_ADMIN_REQUEST_SUCCESS"

export const CREATE_ADMIN_REQUEST = "CREATE_ADMIN_REQUEST"
export const CREATE_ADMIN_REQUEST_SUCCESS = "CREATE_ADMIN_REQUEST_SUCCESS"

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST"
export const DELETE_ADMIN_REQUEST_SUCCESS = "DELETE_ADMIN_REQUEST_SUCCESS"

export const GET_ADMIN_DETAIL_REQUEST = "GET_ADMIN_DETAIL_REQUEST"
export const GET_ADMIN_DETAIL_REQUEST_SUCCESS = "GET_ADMIN_DETAIL_REQUEST_SUCCESS"

export const UPDATE_ADMIN_REQUEST = "UPDATE_ADMIN_REQUEST"
export const UPDATE_ADMIN_REQUEST_SUCCESS = "UPDATE_ADMIN_REQUEST_SUCCESS"

export const UPDATE_ADMIN_PASSWORD_REQUEST = "UPDATE_ADMIN_PASSWORD_REQUEST"
export const UPDATE_ADMIN_PASSWORD_REQUEST_SUCCESS = "UPDATE_ADMIN_PASSWORD_REQUEST_SUCCESS"
