import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withRouter from "../../../components/Common/withRouter";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Collapse,
  Row,
} from "reactstrap";

import { isEmpty, map } from "lodash";

// RangeSlider
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

//Import data
import { discountData, productsData, colorData } from "../../../common/data";

//Import actions
import { getProducts as onGetProducts } from "../../../store/e-commerce/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

const EcommerceProducts = props => {

  document.title=" Products | Minible - Responsive Bootstrap 5 Admin Dashboard"

  const dispatch = useDispatch();


  return (
    <React.Fragment>
      <div className="page-content">
      sdafsdf
      </div>
    </React.Fragment>
  );
};

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default withRouter(EcommerceProducts);
