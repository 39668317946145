import {
    GET_ADMIN_REQUEST,
    CREATE_ADMIN_REQUEST,
    DELETE_ADMIN_REQUEST,
    GET_ADMIN_DETAIL_REQUEST,
    UPDATE_ADMIN_REQUEST,
    UPDATE_ADMIN_PASSWORD_REQUEST
} from "./actionTypes";

export const getAdmins = () => ({
    type: GET_ADMIN_REQUEST
})

export const createAdmin = (data) => ({
    type: CREATE_ADMIN_REQUEST,
    payload :data
})

export const deleteAdmin = (data) => ({
    type: DELETE_ADMIN_REQUEST,
    payload :data
})

export const detailAdmin = (data) => ({
    type: GET_ADMIN_DETAIL_REQUEST,
    payload :data
})

export const updateAdmin = (data) => ({
    type: UPDATE_ADMIN_REQUEST,
    payload :data
})

export const updateAdminPassword = (data) => ({
    type: UPDATE_ADMIN_PASSWORD_REQUEST,
    payload :data
})
