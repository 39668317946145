import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, Container, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getLogistics, searchLogistics} from "../../store/actions";

import SearchColumns from "./SearchColumns";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Nulled from "../../components/Null/Nulled";
import {useParams} from "react-router-dom";


const Waiting = props => {
    const dispatch = useDispatch();
    const {string} = useParams();
    const isLoading = useSelector((state) => state.Logistic.isLoading);
    const error = useSelector((state) => state.Logistic.error);
    const array = useSelector((state) => state.Logistic.array);


    useEffect(() => {
        let item = {
            string
        }
        dispatch(searchLogistics(item))
    }, [string])



    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem={'ძებნა'}/>
                    {isLoading ? (
                        <Preloader/>
                    ) : error ? (
                        <Error/>
                    ) : (
                        <Row>
                            {
                                array.length > 0
                                    ?
                                    <Col lg={12} style={{overflowX: 'auto'}}>
                                        <SearchColumns
                                            status={'status'}
                                            array={array}
                                        />

                                    </Col>
                                    : <Nulled/>
                            }
                        </Row>
                    )}
                </div>
            </div>
        </React.Fragment>

    )
}

export default withRouter(Waiting);
