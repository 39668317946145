import { call, put, takeEvery } from "redux-saga/effects"
import {
    DELETE_NULL_FILES_REQUEST,
} from "./actionTypes"

function* deleteNullFilesSaga({ payload }) {
    try {
        const callBack = yield call(deleteNullFilesSagaAsync, payload)
    } catch (err) {
        console.error(err)
    }
}

const deleteNullFilesSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/photo/deleteNullFiles`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${jwt}`
        }
    })
    return await response.json()
}



function* authSaga() {
    yield takeEvery(DELETE_NULL_FILES_REQUEST, deleteNullFilesSaga)
}

export default authSaga
