import PropTypes from 'prop-types';
import React, {useState, useEffect} from "react";

import {connect, useDispatch} from "react-redux"

import {Link, useNavigate} from "react-router-dom";
// Reactstrap
import {Dropdown, DropdownToggle, DropdownMenu, Button} from "reactstrap";

import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import logoSm from "../../assets/images/logo-sm.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";

//i18n
import {withTranslation} from "react-i18next";

// Redux Store
import {
    checkAdmin, getAllInformation,
    showRightSidebarAction,
    toggleLeftmenu,
    // changeSidebarType,
} from "../../store/actions";

const Header = props => {
    const dispatch = useDispatch()
    const history = useNavigate();

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    useEffect(() => {
        let item = {
            history: props.history
        }
        dispatch(checkAdmin(item))
        // dispatch(getDashboard())
    }, [])

    useEffect(() => {
        // Создаем интервал для автообновления
        const intervalId = setInterval(() => {
            dispatch(getAllInformation())
        }, 300000);

        // Очищаем интервал при размонтировании компонента
        return () => clearInterval(intervalId);
    }, [dispatch]); // Убедись, что зависимости корректны

    function tToggle() {
        var body = document.body;
        var windowSize = document.documentElement.clientWidth;

        body.classList.toggle("vertical-collpsed");
        body.classList.toggle("sidebar-enable");
        if (windowSize > 991) {
            (body.getAttribute('data-sidebar-size') === 'sm') && windowSize > 991 ? body.setAttribute('data-sidebar-size', 'lg') : body.setAttribute('data-sidebar-size', 'sm');
        }
    }


    const [searchString, setSearchString] = useState('');
    const [userString, setUserString] = useState('');


    const handleSearch = () =>{
        if (searchString) {
            history(`/list-logistic/search/${searchString}`)
        }
    }


    const handleKeyDownSearch = (event) => {
        if (event.key === 'Enter') {
            if (searchString) {
                history(`/list-logistic/search/${searchString}`)
            }
        }
    }

    const handleKeyDownSearchUser = (event) => {
        if (event.key === 'Enter') {
            if (userString) {
                history(`/search-company/${userString}`)
            }
        }
    }
    const handleUser = () =>{
        if (userString) {
            history(`/search-company/${userString}`)
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={logoDark} alt="" height="20"/>
                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSm} alt="" height="22"/>
                </span>
                                <span className="logo-lg">
                  <img src={logoLight} alt="" height="20"/>
                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
                            id="vertical-menu-btn"
                        >
                            <i className="fa fa-fw fa-bars"/>
                        </button>
                        <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input
                                    onKeyDown={handleKeyDownSearch}
                                    onChange={(e) => setSearchString(e.target.value)}
                                    type="text"

                                    value={searchString}
                                    style={{
                                        paddingLeft: '10px',
                                        borderBottomRightRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        backgroundColor: '#22252f'
                                    }}
                                    className="form-control"
                                    placeholder={"ტვირთები..."}
                                />
                            </div>
                        </div>
                        <div className="app-search d-none d-lg-block" style={{marginLeft: '0px'}}>
                            <div className="position-relative">
                                <Button
                                    onClick={handleSearch}
                                    style={{height: "38px", width: '40px'}}
                                    color="primary"
                                    className="btn btn-primary waves-effect waves-light"
                                >
                                    <span className="uil-search"></span>
                                </Button>

                            </div>
                        </div>

                        <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input
                                    onKeyDown={handleKeyDownSearchUser}
                                    onChange={(e) => setUserString(e.target.value)}
                                    value={userString}
                                    type="text"
                                    style={{
                                        paddingLeft: '10px',
                                        borderBottomRightRadius: '0px',
                                        borderTopRightRadius: '0px',
                                        backgroundColor: '#22252f'
                                    }}
                                    className="form-control"
                                    placeholder={"კომპანია..."}
                                />
                            </div>
                        </div>
                        <div className="app-search d-none d-lg-block" style={{marginLeft: '0px'}}>
                            <div className="position-relative">
                                <Button
                                    onClick={handleUser}
                                    style={{height: "38px", width: '40px'}}
                                    color="success"
                                    className="btn btn-primary waves-effect waves-light"
                                >
                                    <span className="uil-search"></span>
                                </Button>

                            </div>
                        </div>


                    </div>

                    <div className="d-flex">


                        <Dropdown className="d-none d-lg-inline-block ms-1">
                            <button
                                type="button"
                                onClick={() => {
                                    toggleFullscreen();
                                }}
                                className="btn header-item noti-icon waves-effect"
                                data-toggle="fullscreen"
                            >
                                <i className="uil-minus-path"></i>
                            </button>
                        </Dropdown>

                        {/*<NotificationDropdown />*/}

                        <ProfileMenu/>

                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    // changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
    const {
        layoutType,
        showRightSidebar,
        leftMenu,
        leftSideBarType,
    } = state.Layout;
    return {layoutType, showRightSidebar, leftMenu, leftSideBarType};
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    // changeSidebarType,
})(withTranslation()(Header));
