import React, {useEffect, useState} from "react";
import {Card, CardBody, Col, Container, Modal, Row} from "reactstrap";
import Preloader from "../Preloader/Preloader";
import Error from "../Error/Error";
import {
    countryToGeo, courierStatus,
    declarationStatus,
    receiverCondition,
    transportInvoice,
    typeOfShipment,
    typeOfTransport
} from "../../utils/Status";
import {useSelector} from "react-redux";

const ModalDetail = ({modal_xlarge, id, onCloseModal}) => {
    const arrayAdmin = useSelector((state) => state.Admin.array);
    const awbArray = useSelector((state) => state.AWB.array);

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(false);


    useEffect(() => {
        if (modal_xlarge === true) {
            let item = {
                id: id
            }
            getDetail(item)
        } else {
            // dispatch(nullModalProduct())
        }

    }, [modal_xlarge, id])

    async function getDetail(item) {
        let jwt = localStorage.getItem("token")
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/logistic/getDetail/${item.id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            const Data = await response.json();
            setIsLoading(false)
            if (!Data.success) {
                setError(true)
            } else {
                setData(Data.result)
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }

    const tog_large = () =>{
        onCloseModal()
    }

    return (
        <React.Fragment>

            <Modal size="xl" isOpen={modal_xlarge}  toggle={() => { tog_large()  }} >

                {
                    isLoading ? (
                        <Preloader/>
                    ) : error ? (
                        <Error/>
                    ) : (
                        <>
                            <div className="modal-header">
                                <h5
                                    className="modal-title mt-0"
                                    id="myExtraLargeModalLabel"
                                >
                                    {data.company.companyName} |    ტვირთის ID : {data._id}
                                </h5>
                                <button
                                     onClick={() => {
                                         tog_large()
                                    }}
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 '}>გამომგზავნი ქვეყანა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 '}>{countryToGeo(data.senderCountry)}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მიმღები ქვეყანა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>{countryToGeo(data.receiverCountry)}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ტრანსპორტირება: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {typeOfTransport(data.typeOfTransport)}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გადაზიდვის  ტიპი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {typeOfShipment(data.typeOfShipment)}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გამოგზავნის პირობა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.senderCondition}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მიღების პირობა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {receiverCondition(data.receiverCondition)}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პროდუქტის დასახელება: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.productName}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>აღების
                                            მისამართი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.takeAddress}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>დამატებითი
                                            ინფორმაცია: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.additionalDescription}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="12">
                                        <h5 className={'p-0 m-0 pb-2 pt-1 text-center'}>პირველად წონა </h5>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გამომგზავნი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.sender}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>დაზღვევა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.insurance}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ბრუტო
                                            წონა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryGrossWeight}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>კუბატურა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryCubicWeight}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ადგილების
                                            რაოდენობა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryNumberOfSeat}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ზომები: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primarySize}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მოცულობითი
                                            წონა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryVolumeWeight}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პირველადი
                                            თრექინგი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryTracking}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პირველადი
                                            ტარიფი კილოგრამზე: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryRatePerKilogram}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პირველადი
                                            ჯამური ტარიფი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryTotalTariff}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პირველადი
                                            მოგება: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.primary.primaryProfit}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ავიღეთ
                                            ტვირთი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.takeTheCargo}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მივიდა
                                            საწყობში: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.arrivedToTheWarehouse}</p>
                                    </Col>
                                </Row>


                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="12">
                                        <h5 className={'p-0 m-0 pb-2 pt-1 text-center'}>საბოლოო წონა </h5>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ბრუტო
                                            წონა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastGrossWeight}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ადგილების
                                            რაოდენობა : </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastNumberOfSeat}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>ზომები: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastSize}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მოცულობითი
                                            წონა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastVolumeWeight}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საბოლოო
                                            თრექინგი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastTracking}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საბოლო
                                            ტარიფი კილოგრამზე: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastRatePerKilogram}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საბოლო
                                            ჯამური ტარიფი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastTotalTariff}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საბოლო
                                            მოგება: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastProfit}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>კუბატურა: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.last.lastCubicWeight}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>კომენტარი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.additionalCommentary}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="12">
                                        <h5 className={'p-0 m-0 pb-2 pt-1 text-center'}>კომპანია </h5>
                                    </Col>
                                </Row>


                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>კომპანიის
                                            სახელი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.companyName}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საიდენტიფიკაციო
                                            კოდი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.companyId}</p>
                                    </Col>
                                </Row>


                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>CAG
                                            ID: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.cagId}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საკონტაქტო ნომერი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.phone}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>იმეილი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.email}</p>
                                    </Col>
                                </Row>


                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>მისამართი: </h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {data.company.address}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="12">
                                        <h5 className={'p-0 m-0 pb-2 pt-1 text-center'}>სტატუსი </h5>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გადამზიდი:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {arrayAdmin.find(item => item._id === data.transporterId)?.name || ''}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გაყიდვები:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {arrayAdmin.find(item => item._id === data.sellerId)?.name || ''}</p>
                                    </Col>
                                </Row>


                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}> AWB ID:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}> {awbArray.find(item => item._id === data.awbId)?.title || ''}</p>
                                    </Col>
                                </Row>



                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="12">
                                        <h5 className={'p-0 m-0 pb-2 pt-1 text-center'}>დეკლარაციის
                                            სტატუსი </h5>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}> დეკლარაციის
                                            სტატუსი:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>  {declarationStatus(data.declarationStatus)}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>სატრანსპორტო
                                            ინვოისი:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>  {transportInvoice(data.transportInvoice)}</p>
                                    </Col>
                                </Row>
                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>პროდუქციის
                                            ქართული დასახელება:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>  {courierStatus(data.courierStatus)}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>საკურიეროს
                                            სტატუსი:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>  {data.productGeorgianName}</p>
                                    </Col>
                                </Row>

                                <Row style={{borderBottom:'1px solid  #343747'}}>
                                    <Col md="4">
                                        <h5 className={'p-0 m-0 pb-2 pt-1'}>გადახდის თარიღი:</h5>
                                    </Col>
                                    <Col md="8">
                                        <p className={'p-0 m-0 pb-2 pt-1'}>  {data.paymentDate}</p>
                                    </Col>
                                </Row>

                            </div>
                        </>
                    )}

            </Modal>
        </React.Fragment>
    )
}

export default ModalDetail
