import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import withRouter from "../../components/Common/withRouter";
import {Button, Card, CardBody, Col, FormGroup, Label, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {createAdmin, detailAdmin, getAdmins, getUsers, updateAdmin, updateAdminPassword} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import {notification} from "antd";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import Nulled from "../../components/Null/Nulled";
import {useParams} from "react-router-dom";

const modalError = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'ახალი ადმინის დასამატებლად შეავსეთ ყველა ველი',
    });
};


const Detail = props => {
    const dispatch = useDispatch();
    const {id} = useParams();


    const detail = useSelector((state) => state.Admin.detail);

    const candidateRole = useSelector((state) => state.Authorisation.candidate.role);
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState('')

    useEffect(() => {
        let item = {
            id
        }
        dispatch(detailAdmin(item))
    }, [])

    useEffect(() => {

        if (detail._id) {
            setName(detail.name)
            setRole(detail.role)
            setPhone(detail.phone)
            setLogin(detail.login)
        }

    }, [detail])

    const handleAddAdmin = () => {
        if (name, phone, login, role) {
            let item = {
                id :detail._id,
                name,
                phone,
                login,
                role,
            }
            dispatch(updateAdmin(item))
        } else {
            modalError('error');
        }
    }
    const handleUpdatePassword = () => {
        if (name, phone, login, role) {
            let item = {
                id :detail._id,
                password
            }
            dispatch(updateAdminPassword(item))
        } else {
            modalError('error');
        }
    }

    return (
        <React.Fragment>
            {
                candidateRole === 'admin' && <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="სია" breadcrumbItem="რედაქტირება"/>
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">სახელი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">ლოგინი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={login}
                                                        onChange={(e) => setLogin(e.target.value)}
                                                        name="email"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">როლი</Label>
                                                    <select
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        name="role"
                                                        className="form-control">
                                                        <option value="">--</option>
                                                        <option value="admin">Admin</option>
                                                        <option value="sell">Sell</option>
                                                        <option value="logistic">Logistic</option>
                                                        <option value="declarant">Declarant</option>
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                            <Col md="3" style={{paddingTop: '27px'}}>

                                                <Button
                                                    onClick={handleAddAdmin}
                                                    color="primary"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    განახლება
                                                </Button>
                                            </Col>
                                        </Row>


                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="3">
                                                <FormGroup className="mb-3">
                                                    <Label htmlFor="validationCustom01">პაროლი</Label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        name="password"
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="3" style={{paddingTop: '27px'}}>

                                                <Button
                                                    onClick={handleUpdatePassword}
                                                    color="primary"
                                                    className="btn btn-primary waves-effect waves-light"
                                                >
                                                    ახალი პაროლი
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                    </div>
                </div>

            }


        </React.Fragment>

    )
}

export default withRouter(Detail);
