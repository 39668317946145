import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import ecommerceSaga from "./e-commerce/saga"
import invoiceSaga from "./invoices/saga"
import contactsSaga from "./contacts/saga"

import AuthorisationSaga from "./Authorisation/saga"
import AdminSaga from "./Admin/saga"
import CompanySaga from "./Company/saga"
import LogisticSaga from "./Logistic/saga"
import FilesSaga from "./Files/saga"
import AWBSaga from "./AWB/saga"
import InformationSaga from "./Information/saga"
import UploadSaga from "./Upload/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    fork(LayoutSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(ecommerceSaga),
    fork(invoiceSaga),
    fork(contactsSaga),
    fork(AuthorisationSaga),
    fork(AdminSaga),
    fork(CompanySaga),
    fork(LogisticSaga),
    fork(FilesSaga),
    fork(AWBSaga),
    fork(InformationSaga),
    fork(UploadSaga),
  ])
}
