export const GET_FILES_REQUEST = "GET_FILES_REQUEST"
export const GET_FILES_REQUEST_SUCCESS = "GET_FILES_REQUEST_SUCCESS"
export const GET_USER_FILES_REQUEST = "GET_USER_FILES_REQUEST"
export const GET_USER_FILES_REQUEST_SUCCESS = "GET_USER_FILES_REQUEST_SUCCESS"

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST"
export const DELETE_FILE_REQUEST_SUCCESS = "DELETE_FILE_REQUEST_SUCCESS"

export const ADD_FILES_REQUEST = "ADD_FILES_REQUEST"
export const ADD_FILES_REQUEST_SUCCESS = "ADD_FILES_REQUEST_SUCCESS"
