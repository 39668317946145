import {call, all, takeEvery, put} from "redux-saga/effects"
import {
    CREATE_AWB_REQUEST, DELETE_AWB_REQUEST, DELETE_AWB_REQUEST_SUCCESS,
    GET_AWB_DETAIL_REQUEST,
    GET_AWB_DETAIL_REQUEST_SUCCESS,
    GET_AWBES_REQUEST, GET_AWBES_REQUEST_SUCCESS,
    UPDATE_AWB_REQUEST
} from "./actionTypes"

import {
    SuccessResult,
    ErrorResult, GlobalErrorResult
} from "../../components/Notifications/Messages"

import {getTokenFromLocalStorage} from "../../utils/Token";

function* createSaga({payload}) {
    try {
        const callBack = yield call(createSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
            // payload.history.push(`/partner-details/${callBack.result.data._id}`)
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const createSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/awb/create`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getDetailSaga({payload}) {
    try {
        const callBack = yield call(getDetailSagaAsync, payload)
        if (callBack.success){
            yield put({ type: GET_AWB_DETAIL_REQUEST_SUCCESS, callBack })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const getDetailSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/awb/getDetail/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    return await response.json()
}
function* updateSaga({payload}) {
    try {
        const callBack = yield call(updateSagaAsync, payload)
        if (callBack.success){
            SuccessResult('success')
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const updateSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/awb/update/${item.id}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}


function* getsSaga({payload}) {
    try {
        const callBack = yield call(getsSagaAsync, payload)
        if (callBack.success){
            yield put({ type: GET_AWBES_REQUEST_SUCCESS, callBack })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        ErrorResult('error')
        console.error(e)
    }
}

const getsSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/awb/getAll/${item.skip}/${item.limit}/${item.status}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
    return await response.json()
}


function* deleteSaga({payload}) {
    try {
        const callBack = yield call(deleteSagaAsync, payload)
        if (callBack.success){
            yield put({ type: DELETE_AWB_REQUEST_SUCCESS, payload })
        }else {
            ErrorResult('error')
        }
    } catch (e) {
        console.error(e)
    }
}

const deleteSagaAsync = async (item) => {
    let token = getTokenFromLocalStorage()
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/awb/delete`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* rootSaga() {
    try {
        yield all([
            yield takeEvery(CREATE_AWB_REQUEST, createSaga),
            yield takeEvery(GET_AWB_DETAIL_REQUEST, getDetailSaga),
            yield takeEvery(UPDATE_AWB_REQUEST, updateSaga),
            yield takeEvery(GET_AWBES_REQUEST, getsSaga),
            yield takeEvery(DELETE_AWB_REQUEST, deleteSaga)
        ]);
    } catch (error) {
        GlobalErrorResult('error')
    }
}

export default rootSaga;
