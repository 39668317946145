import React from "react";
import {Card, CardBody, CardTitle, Col, Container, Row, Spinner} from "reactstrap";

const Preloader = () => {
    return (
        <Row>
            <Col xl={12}>
                <Card>
                    <CardBody>
                        <div style={{textAlign:'center'}}>
                            <Spinner className="m-1" color="primary" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}
export default Preloader
