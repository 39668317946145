import React, {useEffect, useState} from "react"
import {connect, useDispatch, useSelector} from "react-redux";
import {Col} from "reactstrap"
import withRouter from "../../components/Common/withRouter";
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import {
    deleteAdmin, deleteCompany,
} from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import Nulled from "../../components/Null/Nulled";
import {Link} from "react-router-dom";


const ObjectColumns = props => {
    const dispatch = useDispatch();

    const candidateRole = useSelector((state) => state.Authorisation.candidate.role);
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})


    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            key,
            id
        }
        dispatch(deleteCompany(item))
        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }


    const Columns = [
        {
            dataField: "email",
            text: "ელ-ფოსტა",
            sort: true,
        },
        {
            dataField: "companyName",
            text: "სახელი",
            sort: true,
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            dataField: "address",
            text: "მისამართი",
            sort: true,
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                     <span>
                           <Link to={`/detail-company/${row._id}`}
                                 className="px-3 text-primary"><FeatherIcon icon="edit"
                                                                            className="icon-xs icon me-2 text-pending"/>
                    </Link>

                         {
                             candidateRole === 'admin' &&      <FeatherIcon
                                 style={{cursor: 'pointer'}}
                                 onClick={() => {
                                     handleOpenAlertModal(row._id, key)
                                 }}
                                 icon="trash" className="icon-xs icon me-2 text-danger"/>
                         }

                           </span>
                </>
            ),
        },

    ]
    return (
        <>
            <BootstrapTable
                keyField="_id"
                data={props.array}
                columns={Columns}
            />
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        style={{backgroundColor: '#404451'}}
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default withRouter(ObjectColumns);
