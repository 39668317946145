import {
    CREATE_AWB_REQUEST,
    DELETE_AWB_REQUEST,
    GET_AWB_DETAIL_REQUEST,
    GET_AWBES_REQUEST,
    UPDATE_AWB_REQUEST
} from "./actionTypes"


export const createAWB = (data) => ({
    type: CREATE_AWB_REQUEST,
    payload :data
})

export const getAWBes = (data) => ({
    type: GET_AWBES_REQUEST,
    payload :data
})

export const getAWBDetails = (data) => ({
    type: GET_AWB_DETAIL_REQUEST,
    payload :data
})

export const updateAWB = (data) => ({
    type: UPDATE_AWB_REQUEST,
    payload :data
})

export const deleteAWB = (data) => ({
    type: DELETE_AWB_REQUEST,
    payload :data
})
