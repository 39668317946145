import React, {useEffect, useState} from "react"
import {useNavigate} from 'react-router-dom';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
} from "reactstrap"

//Import Breadcrumb
import {useFormik} from "formik"
import * as Yup from "yup"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import {useDispatch, useSelector} from "react-redux";
import {getCompanies} from "../../store/Company/actions";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";
import ObjectColumns from "../_Company/ObjectColumns";
import Nulled from "../../components/Null/Nulled";
import {createQuestion} from "../../store/Logistic/actions";
import Select from "react-select";


const darkThemeStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#333',
        borderColor: state.isFocused ? '#2684FF' : '#555',
        boxShadow: state.isFocused ? '0 0 0 1px #2684FF' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? '#2684FF' : '#444',
        },
        minWidth: '200px',
        fontSize: '14px',
        color: '#fff',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#aaa',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#2684FF',
        '&:hover': {
            color: '#2684FF',
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#333',
        color: '#fff',
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#2684FF' : '#333',
        color: state.isFocused ? '#fff' : '#ccc',
        '&:hover': {
            backgroundColor: '#2684FF',
            color: '#fff',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#555',
        color: '#fff',
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#fff',
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#2684FF',
            color: '#fff',
        },
    }),
};

const AddRentalCar = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const isLoading = useSelector((state) => state.Company.isLoading);
    const error = useSelector((state) => state.Company.error);
    const array = useSelector((state) => state.Company.array);


    const [skip, setSkip] = useState(0)
    const [companyOptions, setCompanyOptions] = useState( [
        { value: "AK", label: "Alaska" },
        { value: "HI", label: "Hawaii" },
        { value: "CA", label: "California" },
        { value: "NV", label: "Nevada" },
        { value: "OR", label: "Oregon" },
        { value: "WA", label: "Washington" },
    ])
    const [limit, setLimit] = useState(100)
    useEffect(() => {
        let item = {
            skip: 0,
            limit: limit
        }
        dispatch(getCompanies(item))
    }, [])


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            company: "",
        },
        validationSchema: Yup.object({
            company: Yup.string().required("Choose Company"),
        }),
        onSubmit: (values) => {
            let item = {
                values,
                history: history
            }
           dispatch(createQuestion(item))
        }
    })

    useEffect(()=>{
        if (array.length > 0) {
            const options = array.map(company => ({
                value: company._id,
                label: company.companyName
            }));
            setCompanyOptions(options)
        }
    },[array])

    const handleSelectChange = (selectedOption) => {
        validation.setFieldValue("company", selectedOption ? selectedOption.value : "");
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title={process.env.REACT_APP_PROJECT_NAME}
                                 breadcrumbItem="ახალი შეკითხვის დამატება"/>
                    {isLoading ? (
                        <Preloader/>
                    ) : error ? (
                        <Error/>
                    ) : (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault()
                                                validation.handleSubmit()
                                                return false
                                            }}
                                        >
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label className="form-label">კომპანია</Label>
                                                        <Select
                                                            onChange={handleSelectChange}
                                                            onBlur={() => validation.setFieldTouched("company")}
                                                            value={companyOptions.find(option => option.value === validation.values.company)}
                                                            classNamePrefix="select2-selection"
                                                            placeholder="აირჩიეთ კომპანია"
                                                            title="Country"
                                                            // styles={darkThemeStyles}
                                                            options={companyOptions}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <button
                                                        className="btn btn-primary w-100 waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        გაგრძელება
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                    {/*      <Row>
                        {
                            array.length > 0
                                ?
                                <Col lg={12}>
                                    <ObjectColumns
                                        array={array}
                                    />
                                </Col>
                                : <Nulled/>
                        }
                    </Row>
*/}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddRentalCar
